import XtraModal from 'components/XtraModal';
import styled from 'styled-components';

const RegformModal = styled(XtraModal)<{ themeColor: string }>`
  .modal-dialog {
    max-width: 832px;
  }

  .modal-header, .modal-body {
    padding: 15px 24px;
  }

  .modal-body {
    white-space: pre-wrap;
  }

  .modal-footer {
    justify-content: flex-end;

    #modal-positive-button, #modal-cancel-button {
      background-color: ${({ themeColor }) => themeColor ?? 'initial'} !important;
      border: none;
      transition: 0.2s all;
    }
  }
`;

export default RegformModal;
