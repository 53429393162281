import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FormComponentContainer from 'registration_form_builder/Component/FormBuilder/FormComponentContainer';
import QuestionTitle from 'registration_form_builder/Component/FormBuilder/QuestionTitle';
import QuestionHint from 'registration_form_builder/Component/FormBuilder/QuestionHint';

const CopyAttendeeTitle: FC<{ attendeeId: string }> = ({ attendeeId }) => {
  const { t } = useTranslation();

  return (
    <FormComponentContainer>
      <QuestionTitle htmlFor={`copy-data-${attendeeId}`}>
        {t('registration_config.copy_data_from')}
      </QuestionTitle>
      <QuestionHint htmlFor={`copy-data-${attendeeId}-disclaimer`}>
        {t('registration_config.copy_data_disclaimer')}
      </QuestionHint>
    </FormComponentContainer>
  );
};

export default CopyAttendeeTitle;
