import { FC, useCallback } from 'react';
import {
  connect,
  useDispatch,
  shallowEqual,
  useSelector,
} from 'react-redux';
import FormComponentContainer from 'registration_form_builder/Component/FormBuilder/FormComponentContainer';
import QuestionTitle from 'registration_form_builder/Component/FormBuilder/QuestionTitle';

import styled from 'styled-components';
import RequiredDot from 'components/RequiredDot';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'registration_form_builder/Component/FormBuilder/ErrorMessage';
import PaymentRadioGroup from './PaymentRadioGroup';
import useAvailablePaymentMethodsMapping from './useAvailablePaymentMethodsMapping';

const ErrorMessageContainer = styled.div`
  margin-left: 16px;
`;

interface PaymentMethodReduxProps {
  themeColor: string;
  availablePaymentMethods: string[];
  errorMessage: string | null;
}

const PaymentMethod: FC<PaymentMethodReduxProps> = ({
  availablePaymentMethods,
  themeColor,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethod = useSelector((s) => s.paymentPage.paymentMethod.value, shallowEqual);
  const supportedMethods = useAvailablePaymentMethodsMapping(availablePaymentMethods);
  const handlePaymentMethodChange = useCallback((event) => {
    const selectedPaymentMethod = event.target.value;
    dispatch.paymentPage.setPaymentMethod(selectedPaymentMethod);
  }, [dispatch]);
  return (
    <>
      <FormComponentContainer>
        <QuestionTitle htmlFor="payment-method">
          <RequiredDot />
          {t('payment.payment_method', {
            defaultValue: 'Payment Method',
          })}
        </QuestionTitle>
      </FormComponentContainer>
      <PaymentRadioGroup
        value={paymentMethod}
        themeColor={themeColor}
        choices={supportedMethods}
        handleChange={handlePaymentMethodChange}
      />
      {errorMessage && (
        <ErrorMessageContainer>
          <ErrorMessage
            errorMessage={errorMessage}
          />
        </ErrorMessageContainer>
      )}
    </>
  );
};

const mapState = (state) => ({
  themeColor: state.themeColor,
  availablePaymentMethods: state.availablePaymentMethods,
  errorMessage: state.paymentPage.paymentMethod.error,
});

export default connect(
  mapState,
  null,
)(PaymentMethod);
