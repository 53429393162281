import { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { generate } from '@ant-design/colors';
import RegformFieldElement from 'schema/fieldElement';

const DropDownContainer = styled.div`
  width: 100%;
  padding: 16px 24px;
`;

const CopyAttendeeDropdown = ({
  attendeeId,
  copyAttendee,
  themeColor,
  attendee,
  footerColorMode,
  locale,
}) => {
  const themeColorList = generate(themeColor);
  const customStyles = useMemo(() => ({
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      color: isFocused || isSelected ? footerColorMode : provided.color,
    }),
  }), [footerColorMode]);
  let selectedValue;
  const filteredAttendee = attendee.filter(
    (eachAttendee) => eachAttendee.attendeeId !== attendeeId,
  );
  return (
    <DropDownContainer>
      <Select
        value={selectedValue}
        options={[
          {
            value: '',
            label: '-',
          },
          ...filteredAttendee.map((eachAttendee) => {
            const { code: localeCode } = locale;
            const { attendeeTickets, mainForm } = eachAttendee;
            const { ticket: { name } } = attendeeTickets[localeCode]?.[0];
            const attendeeFirstName = mainForm.find((form) => form.typeName === RegformFieldElement.FirstName)?.questionAnswer || '';
            const attendeeLastName = mainForm.find((form) => form.typeName === RegformFieldElement.LastName)?.questionAnswer || '';
            return ({
              value: eachAttendee.attendeeId,
              label: `${name} ${attendeeFirstName} ${attendeeLastName}`.trim(),
            });
          }),
        ]}
        styles={customStyles}
        onChange={(newSelected) => {
          copyAttendee({
            sourceAttendeeId: newSelected.value,
            targetAttendeeId: attendeeId,
          });
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: themeColor,
            primary25: themeColorList[2],
            primary50: themeColorList[1],
            primary75: themeColorList[0],
          },
        })}
      />
    </DropDownContainer>
  );
};

CopyAttendeeDropdown.propTypes = {
  attendeeId: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  footerColorMode: PropTypes.string.isRequired,
  attendee: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired,
  }).isRequired,
  copyAttendee: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  themeColor: state.themeColor,
  attendee: state.attendee,
  footerColorMode: state.footerColorMode,
  locale: state.locale,
});

const mapDispatch = ({
  currentInQueue: { copyAttendee },
}) => ({
  copyAttendee,
});

export default connect(mapState, mapDispatch)(CopyAttendeeDropdown);
