import {
  FC, useEffect, useMemo, useState,
} from 'react';
import { XtraH3 } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface TimeObject {
  minutes: number;
  seconds: number;
}

const calculateTimeLeftFromMilliseconds = (ms: number): TimeObject => {
  const timeInSeconds = Math.floor(ms / 1000);
  const seconds = timeInSeconds % 60;
  const minutes = Math.floor(timeInSeconds / 60);
  return {
    minutes,
    seconds,
  };
};

const StyledXtraH3 = styled(XtraH3)`
  text-align: center;
  margin-top: 24px;
  font-weight: bold;
  font-size: 1.2rem;
`;

const CountdownTimer: FC<{
  endTime: number;
  onExpired?: () => void;
}> = ({
  endTime,
  onExpired,
}) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<number>(endTime - Date.now());

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = endTime - Date.now();
      setTimeLeft(updatedTimeLeft);
      if (updatedTimeLeft < 0) {
        clearInterval(timer);
        if (onExpired) {
          onExpired();
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [endTime]);

  const remainingTimeObject = useMemo(
    () => calculateTimeLeftFromMilliseconds(timeLeft), [timeLeft],
  );

  const timeString = useMemo(
    () => `${`${remainingTimeObject.minutes}`.padStart(2, '0')}:${`${remainingTimeObject.seconds}`.padStart(2, '0')}`,
    [remainingTimeObject.minutes, remainingTimeObject.seconds],
  );

  return (
    <StyledXtraH3>
      {t('payment.time_left', {
        time_string: timeString,
        defaultValue: 'Time Left: %{time_string}',
      })}
    </StyledXtraH3>
  );
};

export default CountdownTimer;
