import styled from 'styled-components';
import XtraText from './XtraText';

const RedirectText = styled(XtraText)`
  color: #5C8DF3;
  font-weight: bold;
  cursor: pointer;
  padding: 4px 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default RedirectText;
