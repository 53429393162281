import { NextPage } from 'next';
import { useEffect } from 'react';
import GlobalStyle from 'GlobalStyle';
import Head from 'next/head';
import i18n from 'i18n';
import RetryButton from 'components/RetryButton';
import App from 'registration_form/App';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { client } from 'registration_form/Model/currentInQueue';
import { ApolloProvider } from '@apollo/client';
import MatomoContextProvider from './matomo/MatomoContextProvider';

const ErrorContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const reloadPage = () => window.location.reload();

export interface RegistrationFormPageProps {
  googleAnalyticsId: string | null;
  googleTagManagerId: string | null;
  regFormUuid: string | null;
  accessKey: string;
  error?: string;
  ogImageUrl: string;
  ogDescription: string;
  plan: string;
  countryCode: string;
  formKind: string;
  rsvpInvitationToken?: string | null;
  eventName: string;
  fontFamily: string;
}

interface RegistrationFormPageReduxProps {
  themeColor: string;
  locale: {
    emsMapping: string;
    code: string;
  };
}

const RegistrationFormPage: NextPage<
  RegistrationFormPageProps & RegistrationFormPageReduxProps
> = ({
  locale: {
    emsMapping: initialLocale,
    code: initialLocaleCode,
  },
  googleAnalyticsId,
  googleTagManagerId,
  error,
  regFormUuid,
  accessKey,
  ogImageUrl,
  ogDescription,
  plan,
  countryCode,
  formKind,
  rsvpInvitationToken,
  themeColor,
  eventName,
  fontFamily,
}) => {
  useEffect(() => {
    i18n.changeLanguage(initialLocale);
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('locale', initialLocaleCode);
      window.history.pushState(null, 'EventX', `?${searchParams.toString()}`);
    }
  }, [initialLocaleCode, initialLocale]);

  useEffect(() => {
    if (googleAnalyticsId) {
      window.ga('create', googleAnalyticsId, { name: 'clientGA' });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <ErrorContainer>
        <p>
          {`Unable to get registration form. Reason: ${error}`}
        </p>
        {error !== 'unauthorized' && <RetryButton onClick={reloadPage} />}
      </ErrorContainer>
    );
  }

  if (!regFormUuid) {
    return (
      <div style={{ padding: 16 }}>
        App has been successfully initialized.
      </div>
    );
  }

  return (
    <ApolloProvider client={client}>
      <MatomoContextProvider>
        <GlobalStyle fontFamily={fontFamily} themeColor={themeColor} />
        <Head>
          <meta property="og:image" content={ogImageUrl} />
          {ogDescription && (
            <>
              <meta name="description" content={ogDescription} />
              <meta property="og:description" content={ogDescription} />
            </>
          )}
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="EventX" />
          <link href={`//fonts.googleapis.com/css2?family=${fontFamily}&display=swap`} rel="preload" as="style" />
          <link href={`//fonts.googleapis.com/css2?family=${fontFamily}&display=swap`} rel="stylesheet" />
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          {googleTagManagerId && (
            <>
              <script
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${googleTagManagerId}');
                  `,
                }}
              />
              <noscript
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: `
                    <iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}" height="0" width="0" style="display:none;visibility:hidden;"></iframe>
                  `,
                }}
              />
            </>
          )}
        </Head>
        <App
          regFormUuid={regFormUuid}
          plan={plan}
          countryCode={countryCode}
          formKind={formKind}
          rsvpInvitationToken={rsvpInvitationToken}
        />
      </MatomoContextProvider>
    </ApolloProvider>
  );
};

const mapState = (state) => ({
  locale: state.locale,
  themeColor: state.themeColor,
});

export default connect(
  mapState,
  null,
)(RegistrationFormPage);
