import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const SpecificTicketTypesHeader = styled.div`
  margin-bottom: 4px;
`;

const DiscountCodeDetailsMessageView = ({
  tickets,
  DiscountCode,
  locale,
}) => {
  let applyTo = 'tickets';
  const {
    discountType,
    discountOffersAvailable,
  } = DiscountCode;
  const { t } = useTranslation();
  const offersAvailable = discountOffersAvailable.nodes[0];
  const { buyQuantity, getQuantity } = offersAvailable;
  let minTicketQuantity = null;
  let maxTicketQuantity = null;
  let ticketGroupIds = [];
  offersAvailable.limitations.forEach((eachOffer) => {
    switch (eachOffer.type) {
      case 'MINIMUM_QUANTITY': {
        minTicketQuantity = eachOffer.minimumQuantity;
        break;
      }
      case 'MAXIMUM_QUANTITY': {
        maxTicketQuantity = eachOffer.maximumQuantity;
        break;
      }
      case 'SPECIFIC_TICKETS': {
        ticketGroupIds = eachOffer.specificTickets.nodes.map(({ ticketId }) => (ticketId));
        break;
      }
      default: {
        break;
      }
    }
  });
  if (ticketGroupIds.length === 0) {
    applyTo = 'all';
  }
  switch (applyTo) {
    case 'all':
      return maxTicketQuantity ? t('promo_code.discount_offers.details.entire_order_with_min_max', {
        min: minTicketQuantity,
        max: maxTicketQuantity,
        offer: offersAvailable[`${locale.code}_description`],
        locale: locale.emsMapping,
      }) : t('promo_code.discount_offers.details.entire_order_with_min', {
        min: minTicketQuantity,
        offer: offersAvailable[`${locale.code}_description`],
        locale: locale.emsMapping,
      });
    case 'tickets':
      switch (discountType) {
        case 'percentage':
        case 'fixed_amount':
          return (
            <>
              <p>
                {
                  (maxTicketQuantity) ? t('promo_code.discount_offers.details.quantity_with_min_max', {
                    min: minTicketQuantity,
                    max: maxTicketQuantity,
                    offer: offersAvailable[`${locale.code}_description`],
                    locale: locale.emsMapping,
                  }) : t('promo_code.discount_offers.details.quantity_with_min', {
                    min: minTicketQuantity,
                    offer: offersAvailable[`${locale.code}_description`],
                    locale: locale.emsMapping,
                  })
                }
              </p>
              <SpecificTicketTypesHeader>
                { t('promo_code.discount_offers.details.specific_ticket_types', { locale: locale.emsMapping }) }
              </SpecificTicketTypesHeader>
              <ul>
                {
                  tickets.map(({ id, name }) => (
                    ticketGroupIds.find((ticketId) => ticketId === id) && (
                      <li key={id}>
                        { name }
                      </li>
                    )
                  ))
                }
              </ul>
            </>
          );
        case 'quantity':
          return (
            <p>
              {
                t('promo_code.discount_offers.details.buy_x_get_y_free', {
                  buys: buyQuantity,
                  gets: getQuantity,
                  ticket_type: tickets.find(({ id }) => ticketGroupIds.indexOf(id) !== -1).name,
                  locale: locale.emsMapping,
                })
              }
            </p>
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

DiscountCodeDetailsMessageView.propTypes = {
  DiscountCode: PropTypes.shape().isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

DiscountCodeDetailsMessageView.default = {
  tickets: null,
};

export default DiscountCodeDetailsMessageView;
