import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import XtraButton from 'components/XtraButton';
import inputCancel from 'images/input_cancel.svg';
import ErrorMessage from 'registration_form_builder/Component/FormBuilder/ErrorMessage';
import DiscountCodeDetailsMessageView from './DiscountCodeDetailsMessageView';

const DiscountCodeContainer = styled.div`
  width: 100%;
`;

const DiscountCodeLabel = styled.label`
  font-weight: bold;
  margin-right: 8px;
  margin-top: 8px;
  vertical-align: top;
`;

const DiscountCodeInputWrapper = styled.div`
  width: 180px;
  display: inline-block;
  vertical-align: top;
`;

const DiscountCodeInputContainer = styled.div`
  width: 100%;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  border: 2px solid  ${({ discountCodeError }) => (discountCodeError ? 'red' : '#DBDEE2')};

  &:hover, &:focus {
    border: 2px solid ${({ themeColor }) => (themeColor)};
  }
`;

const DiscountCodeInput = styled.input`
  width: 90%;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const DiscountCodeInputCancel = styled.img`
  z-index: ${({ state }) => (state === 'entered' ? 1 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'inline-block')};
  transform: ${({ state }) => (state === 'entered' ? 'translateX(0)' : 'translateX(10px)')};
  width: 10%;
  transition: all 0.3s;
  cursor: pointer;
`;

const DiscountCodeApplyButton = styled(XtraButton)`
  height: auto;
  padding: 12px 16px;
  background-color: ${({ themeColor }) => (themeColor)};
  color: ${({ fontColor }) => (fontColor)};
  text-align: center;
  transition: all 0.3s;
  margin-left: 4px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  z-index: ${({ state }) => (state === 'entered' ? 1 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'inline-block')};
  transform: ${({ state }) => (state === 'entered' ? 'translateX(0)' : 'translateX(-50px)')};
`;

const DiscountCodeDetailsMessageViewContainer = styled.div`
  width: 100%;
  display: block;
`;

const DiscountCodeInputBox = ({
  discountCodeEnabled,
  discountCode,
  themeColor,
  fontColor,
  getOrderQuotation,
  resetDiscountCode,
  discountCodes,
  setdiscountCode,
  tickets,
  locale,
  discountCodeError,
  checkOrderExistBeforeAction,
}) => {
  const { t } = useTranslation();
  if (discountCodeEnabled) {
    return (
      <DiscountCodeContainer>
        <DiscountCodeLabel htmlFor="discountCode">
          {`${t('promo_code.discount_code', { locale: locale.emsMapping })}:`}
        </DiscountCodeLabel>
        <DiscountCodeInputWrapper>
          <DiscountCodeInputContainer
            themeColor={themeColor}
            discountCodeError={discountCodeError !== ''}
          >
            <DiscountCodeInput
              type="text"
              id="discountCode"
              value={discountCode}
              onChange={(e) => {
                setdiscountCode(e.target.value);
              }}
            />
            <Transition in={discountCodes.length > 0} timeout={500} mountOnEnter unmountOnExit>
              {(state) => (
                <DiscountCodeInputCancel
                  state={state}
                  src={inputCancel}
                  onClick={() => {
                    setdiscountCode('');
                    resetDiscountCode();
                    checkOrderExistBeforeAction(getOrderQuotation);
                  }}
                />
              )}
            </Transition>
          </DiscountCodeInputContainer>
          <ErrorMessage
            errorMessage={discountCodeError}
            locale={locale}
            code={discountCode}
            direction="left"
          />
        </DiscountCodeInputWrapper>
        <Transition in={discountCode !== ''} timeout={300} mountOnEnter unmountOnExit>
          {(state) => (
            <DiscountCodeApplyButton
              themeColor={themeColor}
              fontColor={fontColor}
              state={state}
              onClick={() => {
                checkOrderExistBeforeAction(getOrderQuotation, discountCode);
              }}
            >
              {t('general.apply', { locale: locale.emsMapping })}
            </DiscountCodeApplyButton>
          )}
        </Transition>
        {discountCodes.map((eachDiscountCode) => (
          <DiscountCodeDetailsMessageViewContainer
            key={eachDiscountCode.code}
            className="discount-message"
          >
            <DiscountCodeDetailsMessageView
              tickets={tickets}
              DiscountCode={eachDiscountCode}
              locale={locale}
            />
          </DiscountCodeDetailsMessageViewContainer>
        ))}
      </DiscountCodeContainer>
    );
  }
  return null;
};

DiscountCodeInputBox.propTypes = {
  discountCodeEnabled: PropTypes.bool.isRequired,
  discountCode: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  getOrderQuotation: PropTypes.func.isRequired,
  resetDiscountCode: PropTypes.func.isRequired,
  discountCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setdiscountCode: PropTypes.func.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  discountCodeError: PropTypes.string.isRequired,
  checkOrderExistBeforeAction: PropTypes.func.isRequired,
};

export default DiscountCodeInputBox;
