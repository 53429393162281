import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{ fontFamily: string; themeColor: string }>`
  * {
    font-family: ${({ fontFamily }) => fontFamily}, sans-serif;
  }

  .btn-primary, .btn-primary:active, .btn-primary:visited {
    background-color: ${({ themeColor }) => themeColor} !important;
  }

  .btn-primary:hover, .btn-primary:focus {
    opacity: 0.7 !important;
  }

  .MuiOutlinedInput-input {
    font-family: ${({ fontFamily }) => fontFamily}, sans-serif !important;
    padding: 11.5px 14px !important;
  }

  .MuiOutlinedInput-inputMultiline {
    padding: 0 !important;
  }

  .MuiAutocomplete-input {
    padding: 2.5px 8px !important;
  }

  html, body, #__next {
    height: 100%;
  }
`;

export default GlobalStyle;
