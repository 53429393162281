import { FC } from 'react';
import { XtraButton } from 'components';
import { useTranslation } from 'react-i18next';

interface RetryButtonProps {
  onClick: () => void;
}

const RetryButton: FC<RetryButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <XtraButton onClick={onClick}>
      {t('webinars.retry')}
    </XtraButton>
  );
};

export default RetryButton;
