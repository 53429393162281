import {
  FC,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  connect, useSelector, shallowEqual, useDispatch,
} from 'react-redux';
import styled from 'styled-components';
import TextInput from 'registration_form_builder/Component/FormBuilder/TextInput';
import ErrorMessage from 'registration_form_builder/Component/FormBuilder/ErrorMessage';
import QuestionTitle from 'registration_form_builder/Component/FormBuilder/QuestionTitle';
import { useTranslation } from 'react-i18next';
import {
  ContinueButton, XtraLoadingOverlay,
} from 'components';

import PaymentMethod from 'components/payment/PaymentMethod';
import RequiredDot from 'components/RequiredDot';
import CountdownTimer from 'components/payment/CountdownTimer';
import { sendGa } from 'helpers/ga';
import isAnyTicketPaid from 'helpers/isAnyTicketPaid';
import scrollToTop from 'helpers/scrollToTop';
import LocaleSelector from './LocaleSelector';
import FormTicketSummary from './FormTicketSummary';
import OfflinePaymentModal from './OfflinePaymentModal';

interface RegFormPageProps {
  themeColor: string;
  fontColor: string;
}

const PaymentPageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  flex-shrink: 0;

  @media screen and (max-width: 832px) {
    margin: 0;
    border-radius: 0;
  }
`;

const NextButtonContainer = styled.div`
  width: 100%;
  padding: 16px 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  text-align: right;
`;

const LoadingIconContainer = styled.div<{ translateY?: string; marginLeft?: string }>`
  position: relative;
  width: 24px;
  display: inline-block;
  height: auto;
  transform: translateY(${({ translateY }) => (translateY || '-12px')});
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
`;

const FormTicketSummaryContainer = styled.div`
  margin: 16px;
`;

const FormComponentContainer = styled.div`
  width: 100%;
  height: auto;
  margin: auto;
  padding: 16px 24px 0 24px;
`;

interface ReduxPaymentPageProps {
  updateLocale: (...args: unknown[]) => unknown,
  orderEndTime: number,
  isSubmiting: boolean;
  initiatePayment: () => Promise<unknown>;
  errorMessage: string | null;
  actualPrice: string;
  locale: { code: string };
  ticket: any[];
  setOrderExpired: () => void;
  discountCodeEnabled: boolean;
}

const BillingEmailField: FC<{ themeColor: string }> = ({ themeColor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // TODO: Set billing email to be the first email entered by an attendee
  const billingEmail = useSelector((s) => s.paymentPage.billingEmail, shallowEqual);

  const handleBillingEmailChange = useCallback(({ value }) => {
    dispatch.paymentPage.setBillingEmail(value);
  }, []);

  return (
    <>
      <FormComponentContainer>
        <QuestionTitle htmlFor="billing-email">
          <RequiredDot />
          {t('payment.billing_email', {
            defaultValue: 'Billing Email',
          })}
        </QuestionTitle>
      </FormComponentContainer>
      <TextInput
        id="billing-email"
        value={billingEmail}
        type="email"
        disabled={false}
        handleChange={handleBillingEmailChange}
        attrName="questionAnswer"
        themeColor={themeColor}
      />
    </>
  );
};

const PaymentPage: FC<RegFormPageProps & ReduxPaymentPageProps> = ({
  themeColor,
  fontColor,
  orderEndTime,
  updateLocale,
  actualPrice,
  ticket,
  locale,
  isSubmiting,
  initiatePayment,
  discountCodeEnabled,
  errorMessage,
  setOrderExpired,
}) => {
  useEffect(() => {
    sendGa('Entered Payment Page');
    scrollToTop();
  }, []);
  const { t } = useTranslation();
  const hasPaidTickets = useMemo(
    () => isAnyTicketPaid(
      ticket,
      actualPrice,
      locale,
      discountCodeEnabled,
    ),
    [actualPrice],
  );
  return (
    <PaymentPageContainer>
      <OfflinePaymentModal />
      <LocaleSelector setLocale={updateLocale} />
      <CountdownTimer endTime={orderEndTime} onExpired={setOrderExpired} />
      {hasPaidTickets && (
        <PaymentMethod />
      )}
      <FormTicketSummaryContainer>
        <FormTicketSummary forceInline />
      </FormTicketSummaryContainer>
      <NextButtonContainer>
        <ContinueButton
          disabled={isSubmiting}
          onClick={initiatePayment}
          themeColor={themeColor}
          fontColor={fontColor}
        >
          {t('regform_v3.continue')}
          {isSubmiting && (
            <LoadingIconContainer marginLeft="4px" translateY="-21px">
              <XtraLoadingOverlay isLoading size={20} thickness={3} color={fontColor} />
            </LoadingIconContainer>
          )}
        </ContinueButton>
        {errorMessage && (
          <ErrorMessage
            errorMessage={errorMessage}
          />
        )}
      </NextButtonContainer>
    </PaymentPageContainer>
  );
};

const mapState = (state) => ({
  orderEndTime: state.orderEndTime,
  isSubmiting: state.isSubmiting,
  errorMessage: state.paymentPage.mainPage.error,
  actualPrice: state.actualPrice,
  ticket: state.ticket,
  locale: state.locale,
  discountCodeEnabled: state.discountCodeEnabled,
});

const mapDispatch = ({
  locale: { updateLocale },
  formError: { setOrderExpired },
  currentInQueue: { orderInitiatePaymentV4Mutation: initiatePayment },
}) => ({
  updateLocale,
  initiatePayment,
});

export default connect(
  mapState,
  mapDispatch,
)(PaymentPage);
