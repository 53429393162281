import { FC } from 'react';
import styled from 'styled-components';
import { XtraFlexbox, XtraErrorText, XtraHintText } from 'components';
import { useTranslation } from 'react-i18next';

const FormErrorContainer = styled(XtraFlexbox)`
  background-color: white;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 16px;
  padding: 0 16px;
`;

const FormSubmitErrorText = styled(XtraHintText)`
  font-size: 16px;
  letter-spacing: initial;
`;

const FormErrorTitle = styled(XtraErrorText)`
  margin-top: 0;
  font-weight: bold;
  font-size: 1em;
`;

const FormSubmitError: FC<{ reason: string }> = ({ reason }) => {
  const { t } = useTranslation();

  return (
    <FormErrorContainer direction="column" justifyContent="center">
      <FormErrorTitle>{t(`regform_v3.form_error.${reason}.title`)}</FormErrorTitle>
      <FormSubmitErrorText>{t(`regform_v3.form_error.${reason}.content`)}</FormSubmitErrorText>
    </FormErrorContainer>
  );
};

export default FormSubmitError;
