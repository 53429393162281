import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const OrderSummaryTicketEntryRowContainer = styled.div`
  display: flex;
`;

const OrderSummaryTicketName = styled.div`
  flex: 0 0 100%;
  color: #484848;
  padding-bottom: 8px;
  padding-bottom: 6px;
`;

const OrderSummaryTicketPrice = styled.div`
  flex: 0 0 50%;
  color: #b7bfc6;
  font-size: 85%;
  padding-bottom: 12px;
`;

const OrderSummaryTicketQuantity = styled.div`
  flex: 0 0 50%;
  color: #b7bfc6;
  text-align: right;
  font-size: 85%;
`;

const OrderSummaryTicketEntry = ({
  locale,
  ticketPurchase,
  numberFormatter,
}) => {
  const { t } = useTranslation();
  if (ticketPurchase.selectedQuatity === 0) return null;

  const tickek = ticketPurchase[locale.code].ticket;
  const tickekPrice = parseFloat(tickek.price.amount);
  const isFreeTickek = tickekPrice === 0;
  const tickekPriceForDisplay = numberFormatter.format(tickekPrice);
  return (
    <>
      <OrderSummaryTicketEntryRowContainer>
        <OrderSummaryTicketName>{tickek.name}</OrderSummaryTicketName>
      </OrderSummaryTicketEntryRowContainer>
      <OrderSummaryTicketEntryRowContainer>
        <OrderSummaryTicketPrice>
          {isFreeTickek ? t('payment.free', { locale: locale.emsMapping }) : tickekPriceForDisplay }
        </OrderSummaryTicketPrice>
        <OrderSummaryTicketQuantity>
          {`x ${ticketPurchase.selectedQuatity}`}
        </OrderSummaryTicketQuantity>
      </OrderSummaryTicketEntryRowContainer>
    </>
  );
};

OrderSummaryTicketEntry.propTypes = {
  ticketPurchase: PropTypes.shape().isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  numberFormatter: PropTypes.shape().isRequired,
};

export default OrderSummaryTicketEntry;
