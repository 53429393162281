import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import XtraFlexbox from 'components/XtraFlexbox';
import {
  RegformModal,
} from 'components/modal';

const TicketWarningModal = ({
  isWarningModalShowing,
  setIsWarningModalShowing,
  setAlreadyWarned,
  themeColor,
  removeAllAttendeeFromOrder,
  setLargestFormStage,
  formStage,
}) => {
  const { t } = useTranslation();
  const toggleTicketWarningMedal = useCallback(() => {
    setIsWarningModalShowing(!isWarningModalShowing);
  }, [setIsWarningModalShowing, isWarningModalShowing]);
  return (
    <RegformModal
      show={isWarningModalShowing}
      title={t('regform_v3.ticket_warning_modal.title')}
      closeButton
      themeColor={themeColor}
      onHide={toggleTicketWarningMedal}
      positiveBtnOnClick={() => {
        setAlreadyWarned(true);
        setIsWarningModalShowing(false);
        setLargestFormStage(formStage);
        removeAllAttendeeFromOrder();
      }}
      positiveBtnLabel={t('general.confirm')}
      cancelBtnLabel={t('general.cancel')}
      centered
    >
      <XtraFlexbox>
        {t('regform_v3.ticket_warning_modal.content')}
      </XtraFlexbox>
    </RegformModal>
  );
};

TicketWarningModal.propTypes = {
  isWarningModalShowing: PropTypes.bool.isRequired,
  setIsWarningModalShowing: PropTypes.func.isRequired,
  setAlreadyWarned: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  removeAllAttendeeFromOrder: PropTypes.func.isRequired,
  setLargestFormStage: PropTypes.func.isRequired,
  formStage: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  isWarningModalShowing: state.isWarningModalShowing,
  alreadyWarned: state.alreadyWarned,
  themeColor: state.themeColor,
  formStage: state.formStage,
});

const mapDispatch = ({
  isWarningModalShowing: { setIsWarningModalShowing },
  alreadyWarned: { setAlreadyWarned },
  currentInQueue: { removeAllAttendeeFromOrder },
  largestFormStage: { setLargestFormStage },
}) => ({
  setIsWarningModalShowing,
  setAlreadyWarned,
  removeAllAttendeeFromOrder,
  setLargestFormStage,
});

export default connect(mapState, mapDispatch)(TicketWarningModal);
