
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShoppingCartSrc from 'registration_form/Image/shopping_cart.svg';
import { Transition } from 'react-transition-group';

const ShoppingCartContainer = styled.div`
  width: 65px;
  height: 65px;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: none;

  @media screen and (max-width: 832px) {
    display: block;
  }
`;

const ShoppingCartImg = styled.img`
  position: absolute;
  padding: 16px;
  top: 0;
  left: 0;
`;

const ShoppingCartCountDot = styled.div`
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ themeColor }) => (themeColor)};
  color: ${({ fontColor }) => (fontColor)};
  position: absolute;
  top: 12px;
  right: 13px;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  transition: all 0.3s;
`;

const ShoppingCart = ({
  setShowResponsiveSummary,
  showResponsiveSummary,
  themeColor,
  fontColor,
  qty,
}) => (
  <ShoppingCartContainer
    onClick={() => setShowResponsiveSummary(!showResponsiveSummary)}
  >
    <ShoppingCartImg
      src={ShoppingCartSrc}
    />
    <Transition in={qty !== 0} timeout={500} mountOnEnter unmountOnExit>
      {(state) => (
        <ShoppingCartCountDot
          state={state}
          themeColor={themeColor}
          fontColor={fontColor}
        >
          {qty}
        </ShoppingCartCountDot>
      )}
    </Transition>
  </ShoppingCartContainer>
);

ShoppingCart.propTypes = {
  setShowResponsiveSummary: PropTypes.func.isRequired,
  showResponsiveSummary: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
};

export default ShoppingCart;
