import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RadioButton from 'registration_form_builder/Component/FormBuilder/RadioButton';
import { PaymentMethodData } from './PaymentMethodData';

const Item = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 6px 0;
  border: 2px solid #dbdee2;
  margin: 8px 0;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
`;

const RadioButtonContainer = styled.div`
  padding: 4px 24px 0;
`;

const TrailingImagesContainer = styled.div`
  position: absolute;
  right: 16px;
  display: flex;
`;

const TrailingImage = styled.img`
  height: 24px;
  display: flex;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const PaymentRadio = ({
  paymentOption, checked, handleChange, themeColor, isDisabled,
}) => {
  const handleRadioClick = useCallback(() => {
    handleChange({
      target: {
        value: paymentOption.value,
      },
    });
  }, [handleChange, paymentOption.value]);

  return (
    <Item onClick={handleRadioClick}>
      <RadioButton
        backgroundColor={themeColor}
        value={paymentOption.value}
        onChange={handleChange}
        label={paymentOption.label}
        sublabel={paymentOption.sublabel}
        disabled={isDisabled}
        checked={checked}
        attendeeId="none"
      />
      {paymentOption.trailingImages && (
      <TrailingImagesContainer>
        {paymentOption.trailingImages.en.map(({ src, alt }) => (
          <TrailingImage src={src} alt={alt} key={src} />
        ))}
      </TrailingImagesContainer>
      )}
    </Item>
  );
};

const PaymentRadioMemo = React.memo(PaymentRadio);

const PaymentRadioGroup = ({
  choices,
  handleChange,
  isDisabled,
  themeColor,
  value,
}: {
  handleChange: any;
  themeColor: string;
  isDisabled?: boolean;
  choices: PaymentMethodData[];
  value: string;
}) => (
  <RadioButtonContainer>
    {choices && choices.map((eachOption) => (
      <PaymentRadioMemo
        paymentOption={eachOption}
        handleChange={handleChange}
        isDisabled={isDisabled}
        themeColor={themeColor}
        checked={eachOption.value === value}
        key={eachOption.value}
      />
    ))}
  </RadioButtonContainer>
);

PaymentRadioGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
};

PaymentRadioGroup.defaultProps = {
  handleChange: () => {},
};

export default PaymentRadioGroup;
