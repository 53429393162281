import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect, batch } from 'react-redux';
import { Collapse } from 'react-collapse';
import Arrow from 'images/arrow.svg';
import FormComponent from 'registration_form_builder/Component/FormBuilder/FormComponent';
import XtraFlexbox from 'components/XtraFlexbox';
import XtraH2 from 'components/XtraH2';
import CopyAttendeeDropdown from 'registration_form/CopyAttendeeDropdown';
import RegformFieldElement from 'schema/fieldElement';
import CopyAttendeeTitle from './CopyAttendeeTitle';

const AttendeeTicket = styled(XtraFlexbox)`
  position: sticky;
  width: 100%;
  top: 0;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid #DBDEE2;
  align-items: center;
  padding: 12px 0;
`;

const ArrowButton = styled.img`
  transform: ${({ isOpened }) => (isOpened ? 'rotate(0)' : 'rotate(-90deg)')};
  padding: 24px;
  cursor: pointer;
`;

const TicketDetails = styled(XtraH2)`
  display: block;
  margin-left: ${({ isSingleTicket }) => (isSingleTicket ? '24px' : '0')};
  font-family: unset;
`;

const AttendeeForm = ({
  updateComponentAttr,
  eachAttendee,
  locale,
  form,
  updateComponentAttrMutation,
  themeColor,
  forceComponentAttrMutation,
  industriesOption,
  userLocation,
  salutations,
  enableCopyAttendees,
  isSingleTicket,
  showTicketDetails,
  resetLogicalQuestionValues,
  formKind,
  footerColorMode,
  index: attendeeIndex,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const {
    attendeeTickets,
  } = eachAttendee;
  const ticketDetails = useMemo(() => (
    {
      name: attendeeTickets[locale.code][0].ticket.name,
      currencyCode: attendeeTickets[locale.code][0].ticket.price.currencyCode,
      amount: attendeeTickets[locale.code][0].ticket.price.amount,
    }
  ), [attendeeTickets, locale]);
  const numberFormatter = useMemo(() => {
    let language = 'en';
    if (ticketDetails.currencyCode === 'EUR') {
      language = 'de';
    }
    return new Intl.NumberFormat(language,
      {
        style: 'currency',
        currency: ticketDetails.currencyCode,
      });
  }, []);
  const intlTicketPrice = useMemo(() => (
    numberFormatter.format(parseFloat(ticketDetails.amount).toFixed(2))
  ), [ticketDetails]);

  const isComponentDisabled = (typeName) => {
    let isDisabled = false;
    if (formKind !== 'rsvp') return isDisabled;
    switch (typeName) {
      case RegformFieldElement.FirstName:
      case RegformFieldElement.LastName:
      case RegformFieldElement.PrimaryEmail:
        isDisabled = true;
        break;
      default:
        break;
    }
    return isDisabled;
  };

  return (
    <>
      {showTicketDetails && (
        <AttendeeTicket>
          {!isSingleTicket && (
            <ArrowButton
              src={Arrow}
              isOpened={isOpened || eachAttendee.hasError !== ''}
              onClick={() => { setIsOpened(!isOpened); }}
            />
          )}
          <TicketDetails isSingleTicket={isSingleTicket}>
            {ticketDetails.name}
            {ticketDetails.amount !== '0.00' && ` - ${intlTicketPrice}`}
          </TicketDetails>
        </AttendeeTicket>
      )}
      <Collapse
        isOpened={isOpened || eachAttendee.hasError !== ''}
      >
        {enableCopyAttendees && attendeeIndex > 0 && (
          <>
            <CopyAttendeeTitle attendeeId={eachAttendee.attendeeId} />
            <CopyAttendeeDropdown
              attendeeId={eachAttendee.attendeeId}
            />
          </>
        )}
        {form[0].map((eachComponent, index) => (
          <FormComponent
            locale={locale}
            attendeeId={eachAttendee.attendeeId}
            eachComponent={eachComponent[locale.code]}
            questionAnswer={eachAttendee.mainForm[index].questionAnswer}
            errorMessage={eachAttendee.mainForm[index].errorMessage}
            key={eachComponent[locale.code].id}
            handleChange={(props) => {
              batch(() => {
                updateComponentAttr(props);
                updateComponentAttrMutation(props);
                resetLogicalQuestionValues(props);
              });
            }}
            updateComponentAttr={updateComponentAttr}
            updateComponentAttrMutation={updateComponentAttrMutation}
            isDisabled={isComponentDisabled(eachComponent.typeName)}
            themeColor={themeColor}
            forceComponentAttrMutation={forceComponentAttrMutation}
            industriesOption={industriesOption}
            userLocation={userLocation}
            salutations={salutations}
            eachAttendee={eachAttendee}
            footerColorMode={footerColorMode}
          />
        ))}
      </Collapse>
    </>
  );
};

const mapState = (state) => ({
  form: state.form,
  industriesOption: state.industriesOption,
  userLocation: state.userLocation,
  salutations: state.salutations,
  locale: state.locale,
  themeColor: state.themeColor,
  formKind: state.formKind,
  footerColorMode: state.footerColorMode,
  enableCopyAttendees: state.featureGuard.copyAttendees,
});

const mapDispatch = ({
  attendee: { updateComponentAttr, resetLogicalQuestionValues },
  form: { forceComponentAttrMutation, formSubmit },
  currentInQueue: { updateComponentAttrMutation },
}) => ({
  updateComponentAttr,
  updateComponentAttrMutation,
  forceComponentAttrMutation,
  formSubmit,
  resetLogicalQuestionValues,
});

AttendeeForm.propTypes = {
  form: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  eachAttendee: PropTypes.shape().isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  updateComponentAttr: PropTypes.func.isRequired,
  updateComponentAttrMutation: PropTypes.func.isRequired,
  forceComponentAttrMutation: PropTypes.func.isRequired,
  resetLogicalQuestionValues: PropTypes.func.isRequired,
  industriesOption: PropTypes.arrayOf(PropTypes.string).isRequired,
  userLocation: PropTypes.string,
  salutations: PropTypes.shape().isRequired,
  isSingleTicket: PropTypes.bool.isRequired,
  showTicketDetails: PropTypes.bool.isRequired,
  formKind: PropTypes.string.isRequired,
  footerColorMode: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  enableCopyAttendees: PropTypes.bool.isRequired,
};

AttendeeForm.defaultProps = {
  form: null,
  userLocation: 'hk',
};

export default connect(mapState, mapDispatch)(AttendeeForm);
