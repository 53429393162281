import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useQuery } from '@apollo/client';
import { fetchEventNameQuery } from 'registration_form/Model/Graphql';

const useLocalizableEventName = (): string => {
  const dispatch = useDispatch();
  const defaultEventName = useSelector((s) => (s as any).eventInfo.name);
  const emsLocaleMapping = useSelector((s) => (s as any).locale.code);
  const accessKey = useSelector((s) => (s as any).orderAccessKey);
  const { data } = useQuery(fetchEventNameQuery, {
    variables: {
      accessKey,
      locale: emsLocaleMapping,
    },
  });
  const localizedEventName = data?.orderByAccessKey?.regform?.eventName;

  useEffect(() => {
    if (localizedEventName) {
      dispatch.eventInfo.setEventName(localizedEventName);
    }
  }, [localizedEventName]);

  return useMemo(
    () => localizedEventName || defaultEventName,
    [localizedEventName, defaultEventName],
  );
};

export default useLocalizableEventName;
