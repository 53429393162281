import { useMemo } from 'react';
import AlipayLogo from 'registration_form/Image/payment/alipay.svg';
import WeChatPayLogo from 'registration_form/Image/payment/wechat-pay.svg';
import VisaLogo from 'registration_form/Image/payment/visa.svg';
import AmexLogo from 'registration_form/Image/payment/american-express.svg';
import MasterCardLogo from 'registration_form/Image/payment/mastercard.svg';
import UnionPayLogo from 'registration_form/Image/payment/union-pay.svg';
import { useTranslation } from 'react-i18next';
import { PaymentMethodData } from './PaymentMethodData';

const useAvailablePaymentMethodsMapping = (
  availablePaymentMethods: string[],
): PaymentMethodData[] => {
  const { t } = useTranslation();
  const supportedPaymentMethodsMapping = useMemo(() => ({
    CREDIT_CARD: {
      label: t('payment.methods.credit_card', {
        defaultValue: 'Credit Card',
      }),
      value: 'CREDIT_CARD',
      trailingImages: {
        en: [
          {
            src: VisaLogo,
            alt: 'Visa',
          },
          {
            src: MasterCardLogo,
            alt: 'MasterCard',
          },
          {
            src: AmexLogo,
            alt: 'American Express',
          },
          {
            src: UnionPayLogo,
            alt: 'Union Pay',
          },
        ],
      },
    },
    ALIPAY_INTL: {
      label: t('payment.methods.alipay', {
        defaultValue: 'Alipay',
      }),
      value: 'ALIPAY_INTL',
      trailingImages: {
        en: [
          {
            src: AlipayLogo,
            alt: 'Alipay',
          },
        ],
      },
    },
    WECHAT_INTL: {
      label: t('payment.methods.wechat', {
        defaultValue: 'WeChat Pay',
      }),
      value: 'WECHAT_INTL',
      trailingImages: {
        en: [
          {
            src: WeChatPayLogo,
            alt: 'Wechat Pay',
          },
        ],
      },
    },
    BANK_TRANSFER: {
      label: t('payment.methods.bank_transfer', {
        defaultValue: 'Bank Transfer',
      }),
      value: 'BANK_TRANSFER',
      trailingImages: {
        en: [],
      },
    },
    CHEQUE: {
      label: t('payment.methods.cheque', {
        defaultValue: 'Cheque',
      }),
      value: 'CHEQUE',
      trailingImages: {
        en: [],
      },
    },
    CUSTOM: {
      label: t('payment.methods.offline', {
        defaultValue: 'Offline Payment',
      }),
      value: 'CUSTOM',
      logos: {
        en: [],
      },
    },
  }), [t]);

  const supportedMethods = availablePaymentMethods.map(
    (methodName) => supportedPaymentMethodsMapping[methodName],
  );

  return useMemo(() => supportedMethods, [supportedMethods]);
};

export default useAvailablePaymentMethodsMapping;
