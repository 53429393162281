import { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Ticket from 'registration_form_builder/Component/FormBuilder/SelectedTicket';
import LocaleSelector from 'registration_form/LocaleSelector';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import FormError from 'registration_form/FormError';
import { sendGa } from 'helpers/ga';
import scrollToTop from 'helpers/scrollToTop';
import useLocalizableEventName from 'hooks/useLocalizableEventName';
import TicketingSummaryContainer from './TicketingSummaryContainer';

const TicketList = styled.div`
  width: 100%;
  min-height: 100px;
  margin: auto;
`;

const TicketContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  justify-content: center;
  background-color: transparent;
  transition-property: all, width;
  transition-duration: 0.5s, 0.3s;

  @media screen and (max-width: 832px) {
    border-radius: 0;
  }
`;

const TicketListContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  flex-shrink: 0;

  @media screen and (max-width: 832px) {
    border-radius: 0;
  }
`;

const EventDetailsContainer = styled.div`
  width: 100%;
  text-align: center;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
`;

const EventName = styled.div`
  padding: 16px;
  margin: auto;
  color: #484848;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
`;

const TicketComponent = styled.div`
  width: 95%;
  margin: 16px auto;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  position: relative;
`;

const StyledXtraLoadingOverlay = styled(XtraLoadingOverlay)`
  position: initial;
`;

const quatityReducer = (accumulator, { selectedQuatity }) => (
  accumulator + selectedQuatity
);

const priceReducer = (accumulator, { selectedQuatity, ticket: { price: { amount } } }) => (
  accumulator + selectedQuatity * parseFloat(amount)
);

const TicketErrorContainer = styled.div`
  text-align: center;
  padding: 16px 0;
`;

const Ticketing = ({
  themeColor,
  fontColor,
  ticket,
  updateTicket,
  addTicketToAttendee,
  isSubmiting,
  setIsSubmiting,
  ticketError,
  setTicketError,
  fetchTicketingPage,
  locale,
  discountCodeEnabled,
  getOrderQuotation,
  updateLocale,
  ticketMaximumPerOrder,
  attendee,
  setIsWarningModalShowing,
  alreadyWarned,
  setAlreadyWarned,
  formStage,
  largestFormStage,
  footerColorMode,
  isTicketLoading,
}) => {
  const localizedEventName = useLocalizableEventName();
  useEffect(() => {
    sendGa('Entered Ticketing Page');
    scrollToTop();
  }, []);

  const ticketOnChange = (params) => {
    if (discountCodeEnabled) {
      getOrderQuotation();
    }
    updateTicket(params);
  };

  const checkOrderExistBeforeAction = (callback, params) => {
    if (attendee.length) {
      if (!alreadyWarned) {
        setAlreadyWarned(false);
        setIsWarningModalShowing(true);
      }
    } else {
      callback(params);
    }
  };

  const initialTicketCount = useMemo(() => (ticket.filter((eachTicket) => {
    const { remainingQuota } = eachTicket[locale.code].ticket;
    if (remainingQuota === undefined || remainingQuota === null) {
      return true;
    }
    return remainingQuota >= eachTicket[locale.code].ticket.minimumQuantity;
  }).length > 1), [ticket, locale]);

  const availableMaxTicketCount = useCallback((maximumQuantity, selectedQuatity) => {
    const totalSelectedQuatity = ticket.reduce(quatityReducer, 0);
    const availableQuantity = ticketMaximumPerOrder - totalSelectedQuatity + selectedQuatity;
    return Math.min(maximumQuantity, availableQuantity);
  }, [ticket, ticketMaximumPerOrder]);

  const totalPrice = useMemo(() => (
    ticket.map((eachTicket) => ({
      id: eachTicket.id,
      selectedQuatity: eachTicket.selectedQuatity,
      ticket: eachTicket[locale.code].ticket,
    })).reduce(priceReducer, 0)), [ticket, locale]);

  return (
    <TicketContainer>
      <TicketListContainer>
        <EventDetailsContainer>
          <LocaleSelector setLocale={updateLocale} />
          <EventName>
            {localizedEventName}
          </EventName>
        </EventDetailsContainer>
        {!isTicketLoading && Boolean(!ticketError.value) && (
        <>
          <TicketList>
            {ticket.map((eachTicket, index) => (
              <TicketComponent key={eachTicket.id}>
                <Ticket
                  maxTicketCount={
                    availableMaxTicketCount(
                      eachTicket[locale.code].ticket.maximumQuantity,
                      eachTicket.selectedQuatity,
                    )
                  }
                  isDisabled
                  themeColor={themeColor}
                  ticketInfo={eachTicket[locale.code]}
                  index={index}
                  onChange={ticketOnChange}
                  locale={locale}
                  discountCodeEnabled={discountCodeEnabled}
                  getOrderQuotation={getOrderQuotation}
                  minTicketCount={eachTicket[locale.code].ticket.minimumQuantity}
                  initialTicketCount={initialTicketCount}
                  selectedQuatity={eachTicket.selectedQuatity}
                  setAlreadyWarned={setAlreadyWarned}
                  firstVisit={largestFormStage === formStage}
                  checkOrderExistBeforeAction={checkOrderExistBeforeAction}
                  footerColorMode={footerColorMode}
                  allowZeroQuantity={eachTicket.allowZeroQuantity}
                />
              </TicketComponent>
            ))}
          </TicketList>
          <TicketingSummaryContainer
            locale={locale}
            themeColor={themeColor}
            ticket={ticket}
            addTicketToAttendee={addTicketToAttendee}
            isSubmiting={isSubmiting}
            setIsSubmiting={setIsSubmiting}
            totalPrice={totalPrice}
            qty={ticket.reduce(quatityReducer, 0)}
            fontColor={fontColor}
            discountCodeEnabled={discountCodeEnabled}
            checkOrderExistBeforeAction={checkOrderExistBeforeAction}
          />
        </>
        )}
        {isTicketLoading && Boolean(!ticketError?.value) && (
          <StyledXtraLoadingOverlay isLoading color="#DEDEDE" />
        )}
        {isTicketLoading && Boolean(ticketError?.value) && (
          <TicketErrorContainer>
            <FormError
              reason={ticketError?.reason}
              onClick={() => {
                setTicketError({ value: false, reason: null });
                fetchTicketingPage();
              }}
            />
          </TicketErrorContainer>
        )}
      </TicketListContainer>
    </TicketContainer>
  );
};

Ticketing.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  ticket: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateTicket: PropTypes.func.isRequired,
  ticketError: PropTypes.shape({
    reason: PropTypes.string,
    value: PropTypes.bool,
  }).isRequired,
  setTicketError: PropTypes.func,
  addTicketToAttendee: PropTypes.func,
  isSubmiting: PropTypes.bool.isRequired,
  setIsSubmiting: PropTypes.func.isRequired,
  discountCodeEnabled: PropTypes.bool.isRequired,
  getOrderQuotation: PropTypes.func.isRequired,
  updateLocale: PropTypes.func.isRequired,
  fetchTicketingPage: PropTypes.func.isRequired,
  ticketMaximumPerOrder: PropTypes.number.isRequired,
  attendee: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setIsWarningModalShowing: PropTypes.func.isRequired,
  alreadyWarned: PropTypes.bool.isRequired,
  formStage: PropTypes.number.isRequired,
  setAlreadyWarned: PropTypes.func.isRequired,
  largestFormStage: PropTypes.number.isRequired,
  footerColorMode: PropTypes.string.isRequired,
  isTicketLoading: PropTypes.bool.isRequired,
};

Ticketing.defaultProps = {
  addTicketToAttendee: () => {},
  setTicketError: () => {},
};

const mapState = (state) => ({
  locale: state.locale,
  ticket: state.ticket,
  isSubmiting: state.isSubmiting,
  discountCodeEnabled: state.discountCodeEnabled,
  ticketError: state.ticketError,
  ticketMaximumPerOrder: state.ticketMaximumPerOrder,
  attendee: state.attendee,
  isWarningModalShowing: state.isWarningModalShowing,
  alreadyWarned: state.alreadyWarned,
  formStage: state.formStage,
  largestFormStage: state.largestFormStage,
  footerColorMode: state.footerColorMode,
  isTicketLoading: state.isTicketLoading,
});

const mapDispatch = ({
  attendee: { addTicketToAttendee },
  ticket: { updateTicket },
  formStage: { setFormStage },
  isSubmiting: { setIsSubmiting },
  ticketError: { setTicketError },
  currentInQueue: { getOrderQuotation, fetchTicketingPage },
  locale: { updateLocale },
  isWarningModalShowing: { setIsWarningModalShowing },
  alreadyWarned: { setAlreadyWarned },
}) => ({
  updateTicket,
  setFormStage,
  addTicketToAttendee,
  fetchTicketingPage,
  setTicketError,
  setIsSubmiting,
  getOrderQuotation,
  updateLocale,
  setIsWarningModalShowing,
  setAlreadyWarned,
});

export default connect(mapState, mapDispatch)(Ticketing);
