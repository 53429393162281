
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import XtraH3 from 'components/XtraH3';
import XtraFlexbox from 'components/XtraFlexbox';

const ResponsiveOrderSummaryContainer = styled.div`
  padding: 16px;
  display: none;

  @media screen and (max-width: 832px) {
    display: ${({ showResponsiveSummary }) => (showResponsiveSummary ? 'block' : 'none')};
  }
`;

const OrderSummaryRow = styled(XtraFlexbox)`
  width: 100%;
  padding: 4px 0;
`;

const OrderSummaryItem = styled.div`
  color: ${({ color }) => (color || '#484848')};
`;

const StyledHr = styled.hr`
  background: #DBDEE2;
  margin-bottom: 0;
  margin-top: 0;
`;

const ResponsiveOrderSummary = ({
  showResponsiveSummary,
  locale,
  themeColor,
  isQuotatingPrice,
  LoadingIconContainer,
  XtraLoadingOverlay,
  qty,
  intlTotalPrice,
  intlDiscountedPrice,
}) => {
  const { t } = useTranslation();

  return (
    <Collapse isOpened={showResponsiveSummary}>
      <ResponsiveOrderSummaryContainer showResponsiveSummary={showResponsiveSummary}>
        <XtraH3>
          {t('regform_v3.order_summary_box.order_summary', { locale: locale.emsMapping })}
        </XtraH3>
        <OrderSummaryRow justifyContent="space-between">
          <OrderSummaryItem>
            {`${t('regform_v3.order_summary_box.qty', { locale: locale.emsMapping })}: `}
          </OrderSummaryItem>
          <OrderSummaryItem color={themeColor}>
            {qty}
          </OrderSummaryItem>
        </OrderSummaryRow>
        <OrderSummaryRow justifyContent="space-between">
          <OrderSummaryItem>
            {`${t('regform_v3.order_summary_box.subtotal', { locale: locale.emsMapping })}: `}
          </OrderSummaryItem>
          <OrderSummaryItem>
            {intlTotalPrice}
          </OrderSummaryItem>
        </OrderSummaryRow>
        <OrderSummaryRow justifyContent="space-between">
          <OrderSummaryItem color="#06AF97">
            {`${t('regform_v3.order_summary_box.discount', { locale: locale.emsMapping })}: `}
          </OrderSummaryItem>
          <OrderSummaryItem color="#06AF97">
            {isQuotatingPrice ? (
              <LoadingIconContainer>
                <XtraLoadingOverlay isLoading size={16} thickness={2} color={themeColor} />
              </LoadingIconContainer>
            ) : `- ${intlDiscountedPrice}`}
          </OrderSummaryItem>
        </OrderSummaryRow>
        <StyledHr />
      </ResponsiveOrderSummaryContainer>
    </Collapse>
  );
};

ResponsiveOrderSummary.propTypes = {
  showResponsiveSummary: PropTypes.bool.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  isQuotatingPrice: PropTypes.bool.isRequired,
  LoadingIconContainer: PropTypes.shape().isRequired,
  XtraLoadingOverlay: PropTypes.func.isRequired,
  qty: PropTypes.number.isRequired,
  intlTotalPrice: PropTypes.string.isRequired,
  intlDiscountedPrice: PropTypes.string.isRequired,
};

export default ResponsiveOrderSummary;
