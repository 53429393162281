import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import XtraText from 'components/XtraText';
import XtraModal from 'components/XtraModal';
import XtraFlexbox from 'components/XtraFlexbox';
import XtraButton from 'components/XtraButton';
import DiscountCodeDetailsMessageView from './DiscountCodeDetailsMessageView';

const DiscountTiggerText = styled(XtraText)`
  color: ${({ themeColor }) => (themeColor)};
  display: inline-block;
  cursor: pointer;
`;

const DiscountCodeDetailsMessageViewContainer = styled.div`
  width: 100%;
  display: block;
`;

const StyledXtraModal = styled(XtraModal)`
  .modal-dialog {
    width: 100%;
    position: fixed;
    margin: 0;
    bottom: 0;
    left: 0;
  }

  .modal-header, .modal-body {
    padding: 15px 24px;
  }
`;

const DiscountCodeInputContainer = styled.div`
  width: 100%;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 4px;
  margin-top: 16px;
  border: 2px solid  ${({ discountCodeError }) => (discountCodeError ? 'red' : '#DBDEE2')};

  &:hover, &:focus {
    border: 2px solid ${({ themeColor }) => (themeColor)};
  }
`;

const DiscountCodeInput = styled.input`
  width: 90%;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const RemoveText = styled(XtraText)`
  color: ${({ themeColor }) => (themeColor)};
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
`;

const ApplyButton = styled(XtraButton)`
  width: 100%;
  margin-top: 16px;
  background-color: ${({ themeColor }) => (themeColor)};
  border: ${({ themeColor }) => (themeColor)};
`;

const DiscountModalTrigger = ({
  locale,
  themeColor,
  discountCode,
  setdiscountCode,
  getOrderQuotation,
  checkOrderExistBeforeAction,
  discountCodes,
  tickets,
  resetDiscountCode,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);
  return (
    <>
      <Transition in={discountCodes.length > 0} timeout={500} mountOnEnter unmountOnExit>
        <>
          {discountCodes.map((eachDiscountCode) => (
            <DiscountCodeDetailsMessageViewContainer
              key={eachDiscountCode.code}
              className="discount-message"
            >
              <DiscountCodeDetailsMessageView
                tickets={tickets}
                DiscountCode={eachDiscountCode}
                locale={locale}
              />
              <RemoveText
                themeColor={themeColor}
                onClick={() => {
                  setdiscountCode('');
                  resetDiscountCode();
                  checkOrderExistBeforeAction(getOrderQuotation);
                }}
              >
                {t('general.remove', { locale: locale.emsMapping })}
              </RemoveText>
            </DiscountCodeDetailsMessageViewContainer>
          ))}
        </>
      </Transition>
      <Transition in={discountCodes.length === 0} timeout={500} mountOnEnter unmountOnExit>
        <DiscountTiggerText
          themeColor={themeColor}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t('promo_code.enter_discount_code', { locale: locale.emsMapping })}
        </DiscountTiggerText>
      </Transition>
      <StyledXtraModal
        show={isOpen}
        onHide={toggleIsOpen}
        closeButton
        positiveBtnLabel={null}
        cancelBtnLabel={null}
      >
        <XtraFlexbox
          justifyContent="center"
          direction="column"
        >
          <XtraText>
            {t('promo_code.enter_discount_code', { locale: locale.emsMapping })}
          </XtraText>
          <DiscountCodeInputContainer
            themeColor={themeColor}
          >
            <DiscountCodeInput
              type="text"
              id="discountCode"
              value={discountCode}
              onChange={(e) => {
                setdiscountCode(e.target.value);
              }}
            />
          </DiscountCodeInputContainer>
          <ApplyButton
            themeColor={themeColor}
            onClick={() => {
              checkOrderExistBeforeAction(getOrderQuotation, discountCode);
              setIsOpen(false);
            }}
          >
            {t('general.apply', { locale: locale.emsMapping })}
          </ApplyButton>
        </XtraFlexbox>
      </StyledXtraModal>
    </>
  );
};

DiscountModalTrigger.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  discountCode: PropTypes.string.isRequired,
  setdiscountCode: PropTypes.func.isRequired,
  getOrderQuotation: PropTypes.func.isRequired,
  checkOrderExistBeforeAction: PropTypes.func.isRequired,
  discountCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  resetDiscountCode: PropTypes.func.isRequired,
};

export default DiscountModalTrigger;
