import styled from 'styled-components';
import XtraButton from '../XtraButton';

const ConfirmButton = styled(XtraButton)<{ themeColor: string }>`
  background-color: ${({ themeColor }) => (themeColor)};
  border-color: ${({ themeColor }) => (themeColor)};
  margin-left: 8px;
`;

export default ConfirmButton;
