import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ContinueButton from 'components/ContinueButton';
import FormComponent from 'registration_form_builder/Component/FormBuilder/FormComponent';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import { mapValues, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { sendGa } from 'helpers/ga';
import scrollToTop from 'helpers/scrollToTop';
import LocaleSelector from './LocaleSelector';
import AccessCodeValidater from './AccessCodeValidater';

const LandingPageContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  flex-shrink: 0;

  @media screen and (max-width: 832px) {
    margin: 0;
    border-radius: 0;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: 16px 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const NextButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const LoadingIconContainer = styled.div`
  position: relative;
  width: 24px;
  display: inline-block;
  height: auto;
  transform: translateY(${({ translateY }) => (translateY || '-12px')});
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
`;

const StyledXtraLoadingOverlay = styled(XtraLoadingOverlay)`
  position: initial;
`;

const ErrorTextContainer = styled.div`
  margin-top: 16px;
`;

const ErrorText = styled.div`
  z-index: ${({ state }) => (state === 'entered' ? 1 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  transition: all 0.3s;
  margin-bottom: 4px;
  transform:
    ${({ state, direction }) => {
    switch (direction) {
      case 'up':
        if (state === 'entered') {
          return 'translateX(0)';
        }
        return 'translateX(-50px)';
      case 'right':
        if (state === 'entered') {
          return 'translateY(0)';
        }
        return 'translateY(-50px)';
      default:
        break;
    }
    return null;
  }};
  color: red;
`;

const LandingPage = ({
  landingPage,
  updateLocale,
  locale,
  themeColor,
  fontColor,
  footerColorMode,
  regFormStatus,
  isSubmiting,
  processToNextState,
  accessCode = {},
  errorText,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    sendGa('Entered Landing Page');
    scrollToTop();
  }, []);

  const accessCodeNotEmpty = useMemo(() => (
    accessCode?.isEnabled ? !accessCode?.code : false
  ), [accessCode?.isEnabled, accessCode?.code]);

  const hasError = useMemo(() => errorText.length > 0, [errorText]);

  return (
    <LandingPageContainer>
      <LocaleSelector setLocale={updateLocale} />
      {landingPage.length
        ? (
          <>
            {landingPage.map((eachComponent) => (
              <FormComponent
                locale={locale}
                eachComponent={eachComponent[locale.code]}
                questionAnswer=""
                errorMessage={eachComponent.errorMessage}
                key={eachComponent[locale.code].id}
                isDisabled={false}
                themeColor={themeColor}
                footerColorMode={footerColorMode}
              />
            ))}
          </>
        )
        : (
          <StyledXtraLoadingOverlay isLoading color="#DEDEDE" />
        )}
      {!!landingPage.length && regFormStatus === 'published'
        && (
          <FooterContainer>
            <ErrorTextContainer>
              <Transition in={hasError} timeout={500} mountOnEnter unmountOnExit>
                {(state) => (
                  errorText.map(({ type, details }) => (
                    <ErrorText key={type} state={state} direction="right">
                      {t(`regform_v3.error_messages.${type}`, { locale: locale.emsMapping, ...(mapValues(keyBy(details, 'key'), 'value')) })}
                    </ErrorText>
                  ))
                )}
              </Transition>
            </ErrorTextContainer>
            <NextButtonContainer>
              <ContinueButton
                disabled={isSubmiting || accessCodeNotEmpty || hasError}
                themeColor={themeColor}
                fontColor={fontColor}
                onClick={processToNextState}
              >
                {t('regform_v3.continue', { locale: locale.emsMapping })}
                {isSubmiting && (
                <LoadingIconContainer marginLeft="4px" translateY="-24px">
                  <XtraLoadingOverlay isLoading size={20} thickness={3} color={fontColor} />
                </LoadingIconContainer>
                )}
              </ContinueButton>
              {accessCode?.isEnabled && <AccessCodeValidater />}
            </NextButtonContainer>
          </FooterContainer>
        )}
    </LandingPageContainer>
  );
};

LandingPage.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  regFormStatus: PropTypes.string.isRequired,
  landingPage: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateLocale: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool.isRequired,
  footerColorMode: PropTypes.string.isRequired,
  accessCode: PropTypes.shape({
    code: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
  }).isRequired,
  processToNextState: PropTypes.func.isRequired,
  errorText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapState = (state) => ({
  landingPage: state.landingPage,
  locale: state.locale,
  regFormStatus: state.regFormStatus,
  includeTicketing: state.includeTicketing,
  isSubmiting: state.isSubmiting,
  attendee: state.attendee,
  footerColorMode: state.footerColorMode,
  needRefetch: state.needRefetch,
  accessCode: state.accessCode,
  errorText: state.errorText,
});

const mapDispatch = ({
  locale: { updateLocale },
  formStage: { setStageToForm, setStageToTicketing },
  attendee: { addTicketToAttendee },
  isSubmiting: { setIsSubmiting },
  landingPage: { processToNextState },
}) => ({
  updateLocale,
  setStageToTicketing,
  setStageToForm,
  addTicketToAttendee,
  setIsSubmiting,
  processToNextState,
});

export default connect(mapState, mapDispatch)(LandingPage);
