import { FC, useEffect } from 'react';
import { Slide } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

const OnlineStatusTrackerContainer = styled.div`
  background-color: #E2654D;
  color: white;
  text-align: center;
  padding: 8px;
`;

const OfflineImage = styled.img`
  margin-right: 8px;
  vertical-align: text-bottom;
`;

const OnlineStatusTracker: FC = () => {
  const onlineStatus = useSelector((s) => s.onlineStatus);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    const updateOnlineStatus = () => {
      const isOnline = !!navigator.onLine;
      dispatch.onlineStatus.setOnlineStatus(isOnline);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <>
      <Head>
        <link rel="prefetch" href="/offline-icon.svg" as="image" />
        <link rel="preload" href="/offline-icon.svg" as="image" />
      </Head>
      {!onlineStatus && (
        <Slide in={!onlineStatus} timeout={150}>
          <OnlineStatusTrackerContainer className="online-status-tracker-container">
            <OfflineImage src="/offline-icon.svg" alt="Offline Icon" />
            {t('general.offline_message')}
          </OnlineStatusTrackerContainer>
        </Slide>
      )}
    </>
  );
};

export default OnlineStatusTracker;
