import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import XtraButton from 'components/XtraButton';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { connect } from 'react-redux';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import XtraFlexbox from 'components/XtraFlexbox';
import ErrorMessage from 'registration_form_builder/Component/FormBuilder/ErrorMessage';
import { mapValues, keyBy } from 'lodash';
import DiscountModalTigger from './DiscountModalTigger';
import DiscountCodeInputBox from './DiscountCodeInputBox';
import ResponsiveOrderSummary from './ResponsiveOrderSummary';
import ShoppingCart from './ShoppingCart';

const SummaryContainer = styled.div`
  width: 100%;
  padding: 16px 24px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
`;

const PriceDetailsContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 832px) {
    display: none;
  }
`;

const ResponsiveDiscountCodeContainer = styled.div`
  width: 100%;
  display: none;

  @media screen and (max-width: 832px) {
    display: block;
  }
`;

const DiscountCodeWrapper = styled.div`
  width: 75%;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 832px) {
    display: none;
  }
`;

const ErrorText = styled.div`
  z-index: ${({ state }) => (state === 'entered' ? 1 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  transition: all 0.3s;
  margin-bottom: 4px;
  transform:
    ${({ state, direction }) => {
    switch (direction) {
      case 'up':
        if (state === 'entered') {
          return 'translateX(0)';
        }
        return 'translateX(-50px)';
      case 'right':
        if (state === 'entered') {
          return 'translateY(0)';
        }
        return 'translateY(-50px)';
      default:
        break;
    }
    return null;
  }};
  color: red;
`;

const QtyAndPriceContainer = styled.div`
  width: 25%;
  display: inline-block;
  text-align: right;

  @media screen and (max-width: 832px) {
    width: 100%;
  }
`;

const QtyContainer = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #484848;
  padding-bottom: 12px;
`;

const QtyText = styled.span`
  color: ${({ themeColor }) => (themeColor)};
`;

const SubTotalContainer = styled.div`
  padding-bottom: 12px;
`;

const DiscountPriceContainer = styled(SubTotalContainer)`
  color: #06AF97;
`;

const ResponsiveTotalPriceContinueButton = styled(XtraFlexbox)`
  width: 80%;
`;

const TotalPriceContainer = styled.div`
  font-size: 14px;
  line-height: 18px;
  padding: 16px 0;

  @media screen and (max-width: 832px) {
    display: none;
  }
`;

const ResponsiveTotalPriceContainer = styled(TotalPriceContainer)`
  display: none;
  margin-right: 8px;
  transition: all 0.3s;

  @media screen and (max-width: 832px) {
    display: inline-block;
    opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  }
`;

const TotalPrice = styled.span`
  color: ${({ themeColor }) => (themeColor)};
  font-weight: bold;
`;

const StyledHr = styled.hr`
  background: #DBDEE2;
  margin-bottom: 0;
  margin-top: 0;
`;

const ContinueButton = styled(XtraButton)`
  width: 100%;
  max-width: 154px;
  height: auto;
  padding: 12px 2px;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 32px;
  text-align: center;
  transition: all 0.3s;
  background-color: ${({ themeColor }) => themeColor};
  color: ${({ fontColor }) => fontColor};
  border: none;
  font-family: unset;

  &:focus {
    background-color: ${({ themeColor }) => themeColor} !important;
    color: ${({ fontColor }) => fontColor};
    opacity: 0.7 !important;
  }

  &:hover {
    background-color: ${({ themeColor }) => themeColor} !important;
    color: ${({ fontColor }) => fontColor};
    opacity: 0.7 !important;
  }
`;

const ContinueButtonContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const LoadingIconContainer = styled.div`
  position: relative;
  width: 24px;
  display: inline-block;
  height: auto;
  transform: translateY(${({ translateY }) => (translateY || '-12px')});
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
`;

const ButtonContainer = styled(XtraFlexbox)`
  width: 100%;
  flex-direction: row;

  @media screen and (max-width: 832px) {
    justify-content: space-between;
    margin-top: 8px;
  }
`;

const ResponsiveErrorMessageContainer = styled.div`
  display: none;

  @media screen and (max-width: 832px) {
    display: block;
  }
`;

const renderTotalPrice = (
  discountCodeEnabled,
  isQuotatingPrice,
  intlActualPrice,
  intlTotalPrice,
  themeColor,
) => {
  if (discountCodeEnabled) {
    if (isQuotatingPrice) {
      return (
        <LoadingIconContainer>
          <XtraLoadingOverlay isLoading size={16} thickness={2} color={themeColor} />
        </LoadingIconContainer>
      );
    }
    return intlActualPrice;
  }
  return intlTotalPrice;
};

const TicketingSummaryContainer = ({
  ticket,
  qty,
  locale,
  totalPrice,
  isSubmiting,
  setIsSubmiting,
  addTicketToAttendee,
  themeColor,
  appliedDiscountCode,
  fontColor,
  discountCodeEnabled,
  getOrderQuotation,
  actualPrice,
  discountedPrice,
  discountCodes,
  isQuotatingPrice,
  resetDiscountCode,
  discountCodeError,
  errorText,
  attendee,
  setStageToForm,
  checkOrderExistBeforeAction,
}) => {
  const [discountCode, setdiscountCode] = useState(appliedDiscountCode);
  const [showResponsiveSummary, setShowResponsiveSummary] = useState(false);
  const { t } = useTranslation();
  const numberFormatter = useMemo(() => {
    let language = 'en';
    if (ticket[0][locale.code].ticket.price.currencyCode === 'EUR') {
      language = 'de';
    }
    return new Intl.NumberFormat(language,
      {
        style: 'currency',
        currency: ticket[0][locale.code].ticket.price.currencyCode,
      });
  }, []);
  const intlTotalPrice = useMemo(() => (
    numberFormatter.format(totalPrice.toFixed(2))
  ), [totalPrice]);
  const intlDiscountedPrice = useMemo(() => (
    numberFormatter.format(discountedPrice)
  ), [discountedPrice]);
  const intlActualPrice = useMemo(() => (
    numberFormatter.format(actualPrice)
  ), [actualPrice]);
  const tickets = ticket.map((eachTicket) => (
    eachTicket[locale.code].ticket
  ));
  return (
    <SummaryContainer>
      <PriceDetailsContainer>
        <DiscountCodeWrapper>
          <DiscountCodeInputBox
            discountCodeEnabled={discountCodeEnabled}
            discountCode={discountCode}
            setdiscountCode={setdiscountCode}
            themeColor={themeColor}
            fontColor={fontColor}
            getOrderQuotation={getOrderQuotation}
            resetDiscountCode={resetDiscountCode}
            discountCodes={discountCodes}
            tickets={tickets}
            locale={locale}
            discountCodeError={discountCodeError}
            checkOrderExistBeforeAction={checkOrderExistBeforeAction}
          />
        </DiscountCodeWrapper>
        <QtyAndPriceContainer>
          <QtyContainer>
            {`${t('regform_v3.order_summary_box.qty', { locale: locale.emsMapping })}: `}
            <QtyText themeColor={themeColor}>
              {qty}
            </QtyText>
          </QtyContainer>
          {discountCodeEnabled && (
            <>
              <SubTotalContainer>
                {`${t('regform_v3.order_summary_box.subtotal', { locale: locale.emsMapping })}: `}
                {intlTotalPrice}
              </SubTotalContainer>
              {parseFloat(discountedPrice) > 0 && (
              <DiscountPriceContainer>
                {`${t('regform_v3.order_summary_box.discount', { locale: locale.emsMapping })}: `}
                {isQuotatingPrice ? (
                  <LoadingIconContainer>
                    <XtraLoadingOverlay isLoading size={16} thickness={2} color={themeColor} />
                  </LoadingIconContainer>
                ) : `- ${intlDiscountedPrice}`}
              </DiscountPriceContainer>
              )}
            </>
          )}
        </QtyAndPriceContainer>
      </PriceDetailsContainer>
      {
        discountCodeEnabled && (
          <ResponsiveDiscountCodeContainer>
            <DiscountModalTigger
              locale={locale}
              themeColor={themeColor}
              discountCode={discountCode}
              setdiscountCode={setdiscountCode}
              getOrderQuotation={getOrderQuotation}
              checkOrderExistBeforeAction={checkOrderExistBeforeAction}
              discountCodes={discountCodes}
              tickets={tickets}
              resetDiscountCode={resetDiscountCode}
            />
          </ResponsiveDiscountCodeContainer>
        )
      }
      <StyledHr />
      <ResponsiveOrderSummary
        showResponsiveSummary={showResponsiveSummary}
        locale={locale}
        themeColor={themeColor}
        isQuotatingPrice={isQuotatingPrice}
        LoadingIconContainer={LoadingIconContainer}
        XtraLoadingOverlay={XtraLoadingOverlay}
        qty={qty}
        intlTotalPrice={intlTotalPrice}
        intlDiscountedPrice={intlDiscountedPrice}
      />
      <ContinueButtonContainer>
        <TotalPriceContainer>
          {t('payment.total', { locale: locale.emsMapping })}
          <TotalPrice themeColor={themeColor}>
            {renderTotalPrice(
              discountCodeEnabled,
              isQuotatingPrice,
              intlActualPrice,
              intlTotalPrice,
              themeColor,
            )}
          </TotalPrice>
        </TotalPriceContainer>
        <Transition in={errorText.length !== 0} timeout={500} mountOnEnter unmountOnExit>
          {(state) => (
            errorText.map(({ type, details }) => (
              <ErrorText key={type} state={state} direction="right">
                {t(`regform_v3.error_messages.${type}`, { locale: locale.emsMapping, ...(mapValues(keyBy(details, 'key'), 'value')) })}
              </ErrorText>
            ))
          )}
        </Transition>
        <ResponsiveErrorMessageContainer>
          <ErrorMessage
            errorMessage={discountCodeError}
            locale={locale}
            code={discountCode}
            direction="left"
          />
        </ResponsiveErrorMessageContainer>
        <ButtonContainer justifyContent="flex-end">
          <ShoppingCart
            setShowResponsiveSummary={setShowResponsiveSummary}
            showResponsiveSummary={showResponsiveSummary}
            themeColor={themeColor}
            fontColor={fontColor}
            qty={qty}
          />
          <ResponsiveTotalPriceContinueButton justifyContent="flex-end">
            <Transition in={qty !== 0} timeout={500} mountOnEnter unmountOnExit>
              {(state) => (
                <ResponsiveTotalPriceContainer state={state}>
                  {t('payment.total', { locale: locale.emsMapping })}
                  <TotalPrice themeColor={themeColor}>
                    {renderTotalPrice(
                      discountCodeEnabled,
                      isQuotatingPrice,
                      intlActualPrice,
                      intlTotalPrice,
                      themeColor,
                    )}
                  </TotalPrice>
                </ResponsiveTotalPriceContainer>
              )}
            </Transition>
            <ContinueButton
              disabled={isSubmiting || qty === 0}
              onClick={() => {
                if (attendee.length) {
                  setStageToForm();
                } else {
                  setIsSubmiting(true);
                  addTicketToAttendee();
                }
              }}
              themeColor={themeColor}
              fontColor={fontColor}
            >
              {t('login.continue', { locale: locale.emsMapping })}
              {isSubmiting && !isQuotatingPrice && (
                <LoadingIconContainer marginLeft="4px" translateY="-24px">
                  <XtraLoadingOverlay isLoading size={20} thickness={3} color={fontColor} />
                </LoadingIconContainer>
              )}
            </ContinueButton>
          </ResponsiveTotalPriceContinueButton>
        </ButtonContainer>
      </ContinueButtonContainer>
    </SummaryContainer>
  );
};

TicketingSummaryContainer.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  ticket: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addTicketToAttendee: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool.isRequired,
  setIsSubmiting: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  fontColor: PropTypes.string.isRequired,
  qty: PropTypes.number.isRequired,
  discountCodeEnabled: PropTypes.bool.isRequired,
  getOrderQuotation: PropTypes.func.isRequired,
  actualPrice: PropTypes.string.isRequired,
  discountedPrice: PropTypes.string.isRequired,
  discountCodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  resetDiscountCode: PropTypes.func.isRequired,
  isQuotatingPrice: PropTypes.bool.isRequired,
  discountCodeError: PropTypes.string.isRequired,
  appliedDiscountCode: PropTypes.string.isRequired,
  errorText: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  attendee: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setStageToForm: PropTypes.func.isRequired,
  checkOrderExistBeforeAction: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  actualPrice: state.actualPrice,
  discountedPrice: state.discountedPrice,
  discountCodes: state.discountCodes,
  appliedDiscountCode: state.appliedDiscountCode[0] || '',
  isQuotatingPrice: state.isQuotatingPrice,
  discountCodeError: state.discountCodeError,
  errorText: state.errorText,
  attendee: state.attendee,
});

const mapDispatch = ({
  discountCodes: { resetDiscountCode },
  formStage: { setStageToForm },
  currentInQueue: { getOrderQuotation },
}) => ({
  resetDiscountCode,
  setStageToForm,
  getOrderQuotation,
});

export default connect(mapState, mapDispatch)(TicketingSummaryContainer);
