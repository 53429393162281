import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import XtraFlexbox from 'components/XtraFlexbox';
import {
  RegformModal,
} from 'components/modal';
import styled from 'styled-components';
import { useCallback } from 'react';

const InvoiceDueDateBox = styled.div`
  font-weight: bold;
  margin-top: 1em;
`;

const options: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  timeZoneName: 'long',
};

interface OfflinePaymentModalReduxProps {
  shouldShowOfflinePaymentModal: boolean;
  eventBankAccountDetails: string;
  themeColor: string;
  invoiceDueDate: string;
  paymentMethod: string;
  hideOfflinePaymentModal: () => void;
  confirmOfflinePayment: () => Promise<void>;
}

const OfflinePaymentModal = ({
  paymentMethod,
  shouldShowOfflinePaymentModal,
  themeColor,
  eventBankAccountDetails,
  hideOfflinePaymentModal,
  confirmOfflinePayment,
  invoiceDueDate,
}: OfflinePaymentModalReduxProps) => {
  const { t } = useTranslation();

  const onConfirmClick = useCallback(() => {
    hideOfflinePaymentModal();
    confirmOfflinePayment();
  }, [confirmOfflinePayment, hideOfflinePaymentModal]);
  const onCancelClick = useCallback(() => {
    hideOfflinePaymentModal();
  }, [hideOfflinePaymentModal]);

  return (
    <RegformModal
      show={shouldShowOfflinePaymentModal}
      title={t(`payment.methods.${paymentMethod?.toLowerCase()}`)}
      closeButton
      themeColor={themeColor}
      onHide={onCancelClick}
      positiveBtnOnClick={onConfirmClick}
      positiveBtnLabel={t('general.confirm')}
      cancelBtnLabel={t('general.cancel')}
      centered
    >
      <XtraFlexbox>
        {eventBankAccountDetails}
      </XtraFlexbox>
      {invoiceDueDate && (
        <InvoiceDueDateBox>
          {t('payment.invoice_due_date', { date_string: '' })}
          <p>{(new Date(invoiceDueDate)).toLocaleString(undefined, options)}</p>
        </InvoiceDueDateBox>
      )}
    </RegformModal>
  );
};

const mapState = (state) => ({
  shouldShowOfflinePaymentModal: state.paymentPage.offlinePayment.showModal,
  invoiceDueDate: state.paymentPage.offlinePayment.invoiceDueDate,
  paymentMethod: state.paymentPage.paymentMethod.value,
  eventBankAccountDetails: state.paymentPage.eventBankAccountDetails,
  themeColor: state.themeColor,
});

const mapDispatch = ({
  paymentPage: { hideOfflinePaymentModal },
  currentInQueue: { confirmOfflinePayment = () => {} },
}) => ({
  hideOfflinePaymentModal,
  confirmOfflinePayment,
});

export default connect(mapState, mapDispatch)(OfflinePaymentModal);
