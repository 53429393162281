import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FormComponent from 'registration_form_builder/Component/FormBuilder/FormComponent';
import { XtraLoadingOverlay } from 'components';
import { sendGa } from 'helpers/ga';
import scrollToTop from 'helpers/scrollToTop';
import LocaleSelector from './LocaleSelector';

const ThankYouPageContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  flex-shrink: 0;

  @media screen and (max-width: 832px) {
    margin: 0;
    border-radius: 0;
  }
`;
const ThankYouPage = ({
  thankYouPage,
  updateLocale,
  locale,
  footerColorMode,
  themeColor,
}) => {
  useEffect(() => {
    sendGa('Entered Thank You Page');
    scrollToTop();
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('order_access_key');
      window.history.pushState(null, 'EventX', `?${searchParams.toString()}`);
    }
  }, []);
  return (
    <ThankYouPageContainer>
      <LocaleSelector setLocale={updateLocale} />
      {thankYouPage?.length ? thankYouPage.map((eachComponent) => (
        <FormComponent
          locale={locale}
          footerColorMode={footerColorMode}
          eachComponent={eachComponent[locale.code]}
          questionAnswer=""
          errorMessage={eachComponent.errorMessage}
          key={eachComponent[locale.code].id}
          isDisabled={false}
          themeColor={themeColor}
        />
      )) : (
        <XtraLoadingOverlay isLoading color="#DEDEDE" />
      )}
    </ThankYouPageContainer>
  );
};
ThankYouPage.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  thankYouPage: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateLocale: PropTypes.func.isRequired,
  footerColorMode: PropTypes.string.isRequired,
};

const mapState = (state) => ({
  thankYouPage: state.thankYouPage,
  locale: state.locale,
  footerColorMode: state.footerColorMode,
  themeColor: state.themeColor,
});

const mapDispatch = ({
  locale: { updateLocale },
}) => ({
  updateLocale,
});

export default connect(mapState, mapDispatch)(ThankYouPage);
