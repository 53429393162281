import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import XtraButton from 'components/XtraButton';
import inputCancel from 'images/input_cancel.svg';
import ErrorMessage from 'registration_form_builder/Component/FormBuilder/ErrorMessage';

const AccessCodeContainer = styled.div`
  width: 100%;
`;

const AccessCodeLabel = styled.label`
  font-weight: bold;
  margin-right: 8px;
  margin-top: 8px;
  vertical-align: top;
`;

const AccessCodeInputWrapper = styled.div`
  width: 180px;
  display: inline-block;
  vertical-align: top;
`;

const AccessCodeInputContainer = styled.div<{ accessCodeError: boolean, themeColor: string }>`
  width: 100%;
  transition: all 0.3s;
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  border: 2px solid  ${({ accessCodeError }) => (accessCodeError ? 'red' : '#DBDEE2')};

  &:hover, &:focus {
    border: 2px solid ${({ themeColor }) => (themeColor)};
  }
`;

const AccessCodeInput = styled.input`
  width: 90%;
  border: none;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const AccessCodeInputCancel = styled.img<{ state: string }>`
  z-index: ${({ state }) => (state === 'entered' ? 1 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'inline-block')};
  transform: ${({ state }) => (state === 'entered' ? 'translateX(0)' : 'translateX(10px)')};
  width: 10%;
  transition: all 0.3s;
  cursor: pointer;
`;

interface AccessCode {
  code: string,
  isEnabled: boolean,
  errorMessage: string,
}

interface AccessCodeInputProps {
  accessCode: AccessCode;
  locale: any
  themeColor: string;
  accessCodeError: string;
  fontColor: string;
  setAccessCode: (accessCode: string) => void
}

const AccessCodeValidater: FC<AccessCodeInputProps> = ({
  accessCode,
  locale,
  themeColor,
  setAccessCode,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setAccessCode(window.localStorage.getItem('accessCode') ?? '');
  }, []);

  return (
    <AccessCodeContainer>
      <AccessCodeLabel htmlFor="accessCode">
        {`${t('promo_code.access_code', { locale: locale.emsMapping })}:`}
      </AccessCodeLabel>
      <AccessCodeInputWrapper>
        <AccessCodeInputContainer
          themeColor={themeColor}
          accessCodeError={accessCode.errorMessage !== ''}
        >
          <AccessCodeInput
            type="text"
            id="accessCode"
            value={accessCode.code}
            onChange={(e) => {
              setAccessCode(e.target.value);
            }}
          />
          <Transition in={accessCode.code !== ''} timeout={500} mountOnEnter unmountOnExit>
            {(state) => (
              <AccessCodeInputCancel
                state={state}
                src={inputCancel}
                onClick={() => {
                  setAccessCode('');
                }}
              />
            )}
          </Transition>
        </AccessCodeInputContainer>
        {accessCode.errorMessage && (
          <ErrorMessage
            errorMessage={accessCode.errorMessage}
            locale={locale}
            code={accessCode.code}
            direction="left"
          />
        )}
      </AccessCodeInputWrapper>
    </AccessCodeContainer>
  );
};

const mapState = (state) => ({
  accessCode: state.accessCode,
  locale: state.locale,
  themeColor: state.themeColor,
  fontColor: state.fontColor,
});

const mapDispatch = ({
  accessCode: { setAccessCode },
}) => ({
  setAccessCode,
});

export default connect(mapState, mapDispatch)(AccessCodeValidater);
