import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import SubmitButton from 'registration_form_builder/Component/FormBuilder/SubmitButton';
import PropTypes from 'prop-types';
import { XtraCheckbox, XtraHintText } from 'components';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'components/payment/CountdownTimer';
import FormSubmitError from 'components/FormSubmitError';
import { sendGa } from 'helpers/ga';
import LocaleSelector from './LocaleSelector';
import AttendeeForm from './AttendeeForm';
import FormTicketSummary from './FormTicketSummary';
import scrollToTop from 'helpers/scrollToTop';

const FormContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease-in;
  background-color: white;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  flex-shrink: 0;

  @media screen and (max-width: 832px) {
    margin: 0;
    border-radius: 0;
  }
`;

const StyledHintText = styled(XtraHintText)`
  color: #A2AAB3;
  font-family: unset;
`;

const StyledCheckbox = styled(XtraCheckbox)`
  margin-top: 16px;
  margin-left: 24px;
`;

const StyledXtraLoadingOverlay = styled(XtraLoadingOverlay)`
  position: initial;
`;

const FormPage = ({
  form,
  attendee,
  formStage,
  themeColor,
  fontColor,
  formSubmit,
  isSubmiting,
  locale,
  updateLocale,
  orderEndTime,
  enableFormCountdown,
  plan,
  includeTicketing,
  ticket,
  isUploadingFile,
  formSubmitError,
  setOrderExpired,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const { t } = useTranslation();
  const handleCheckbox = ({ target: { checked } }) => {
    setIsChecked(checked);
  };
  const hasPaidTicket = ticket.some(({
    selectedQuatity,
    [locale.code]: {
      ticket: {
        price: {
          amount,
        },
      },
    },
  }) => (selectedQuatity > 0 && amount !== '0.00'));

  useEffect(() => {
    sendGa('Entered Form');
    scrollToTop();
  }, []);

  return (
    <>
      {(includeTicketing && hasPaidTicket) && <FormTicketSummary themeColor={themeColor} />}
      <FormContainer>
        <LocaleSelector setLocale={updateLocale} />
        {enableFormCountdown && (
          <CountdownTimer
            endTime={orderEndTime}
            onExpired={setOrderExpired}
          />
        )}
        {form[0] && form[0].length ? (
          <>
            {attendee.map((eachAttendee, index) => (
              <AttendeeForm
                key={eachAttendee.attendeeId}
                index={index}
                eachAttendee={eachAttendee}
                isSingleTicket={attendee.length === 1}
                showTicketDetails={includeTicketing && attendee.length > 1}
              />
            ))}
            {plan === 'free' && (
              <StyledCheckbox
                onChange={handleCheckbox}
                checked={isChecked}
                backgroundColor={themeColor}
                initFontStyle
              >
                <StyledHintText>
                  {ReactHtmlParser(t('regsvp.terms_of_service', { locale: locale.emsMapping }))}
                </StyledHintText>
              </StyledCheckbox>
            )}
            <SubmitButton
              submitText={t('general.submit', { locale: locale.emsMapping })}
              isSubmitingText={t('regsvp.submitting', { locale: locale.emsMapping })}
              onClickFunc={formSubmit}
              themeColor={themeColor}
              fontColor={fontColor}
              formStage={formStage}
              isSubmiting={isSubmiting}
              isDisabled={isSubmiting || isUploadingFile !== 0 || (plan === 'free' && !isChecked)}
            />
            {formSubmitError.value && (
              <FormSubmitError reason={formSubmitError.reason} />
            )}
          </>
        ) : (
          <StyledXtraLoadingOverlay isLoading color="#DEDEDE" />
        )}
      </FormContainer>
    </>
  );
};

FormPage.propTypes = {
  form: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  attendee: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  formStage: PropTypes.number.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  formSubmit: PropTypes.func.isRequired,
  isSubmiting: PropTypes.bool.isRequired,
  updateLocale: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  includeTicketing: PropTypes.bool.isRequired,
  orderEndTime: PropTypes.number.isRequired,
  enableFormCountdown: PropTypes.bool.isRequired,
  ticket: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isUploadingFile: PropTypes.number.isRequired,
  formSubmitError: PropTypes.shape({
    value: PropTypes.bool,
    reason: PropTypes.string,
  }).isRequired,
  setOrderExpired: PropTypes.func.isRequired,
};

FormPage.defaultProps = {
  form: [],
};

const mapState = (state) => ({
  form: state.form,
  attendee: state.attendee,
  formStage: state.formStage,
  locale: state.locale,
  orderEndTime: state.orderEndTime,
  enableFormCountdown: state.featureGuard.formCountdown,
  isSubmiting: state.isSubmiting,
  includeTicketing: state.includeTicketing,
  ticket: state.ticket,
  formSubmitError: state.formSubmitError,
  isUploadingFile: state.isUploadingFile,
});

const mapDispatch = ({
  form: { formSubmit },
  locale: { updateLocale },
  formError: { setOrderExpired },
}) => ({
  updateLocale,
  formSubmit,
  setOrderExpired,
});

export default connect(
  mapState,
  mapDispatch,
)(FormPage);
