import {
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import useLocalizableEventName from 'hooks/useLocalizableEventName';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import FormStage from 'components/FormStage';
import ThankYouPage from 'registration_form/ThankYouPage';
import Footer from 'components/Footer';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import useBuiltInPaymentEnabled from 'hooks/useBuiltInPaymentEnabled';
import AttendeeUpdateQueue from 'helpers/attendeeUpdateQueue';
import Head from 'next/head';
import OnlineStatusTracker from 'components/OnlineStatusTracker';
import LandingPage from './LandingPage';
import Ticketing from './Ticketing';
import FormPage from './FormPage';
import TicketWarningModal from './TicketWarningModal';
import FormError from './FormError';
import PaymentPage from './PaymentPage';

const RootContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ fontFamily }) => fontFamily}, Lato, sans-serif !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

`;

const StageSwitcher = styled.div`
  width: 100%;
  max-width: 832px;
  padding: 16px 16px 0;
  margin: 0 auto;
  transition-property: all;
  transition-duration: 0.5s;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'flex')};
  transform:
    ${({ state, slideLeft }) => {
    const defaultStyle = 'scale(0.7) ';
    if (state === 'entering') {
      if (slideLeft) {
        return `${defaultStyle}translateX(calc(400px))`;
      }
      return `${defaultStyle}translateX(calc(-400px))`;
    } if (state === 'exiting') {
      if (slideLeft) {
        return `${defaultStyle}translateX(calc(-400px))`;
      }
      return `${defaultStyle}translateX(calc(400px))`;
    }
    return 'none';
  }};

  @media screen and (max-width: 832px) {
    padding: 0;
  }
`;

const FormStageContainer = styled.div`
  width: 100%;
  max-width: 832px;
  margin: 0 auto;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  transform: ${({ state }) => (state === 'entered' ? 'none' : 'translateY(-400px)')};
`;

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const App = ({
  createNewOrder,
  regFormUuid,
  backgroundColor,
  formStage,
  setRegFormUuid,
  themeColor,
  plan,
  countryCode,
  footerColorMode,
  includeTicketing,
  setLargestFormStage,
  formError,
  fontFamily,
  setUserLocation,
  rsvpInvitationToken,
  formKind,
  setFormKind,
  rsvpOrderLookupOrCreate,
  eventInfo,
  ogInformation,
  updateWeChatShare,
  wechatOpenId,
  locale,
  orderAccessKey,
}) => {
  const { enableLinkTracking, trackPageView, pushInstruction } = useMatomo();
  const localizableEventName = useLocalizableEventName();

  const isBuiltInPaymentEnabled = useBuiltInPaymentEnabled();

  enableLinkTracking(); // https://github.com/Amsterdam/matomo-tracker/tree/master/packages/react#spa-link-tracking

  useEffect(() => {
    trackPageView({});
    pushInstruction('appendToTrackingUrl', '');
  }, [pushInstruction, trackPageView]);

  useEffect(() => {
    setFormKind(formKind);
    setRegFormUuid(regFormUuid);
    setUserLocation(countryCode);

    if (orderAccessKey) {
      AttendeeUpdateQueue.setAccessKey(orderAccessKey);
    }

    if (formKind === 'rsvp') {
      rsvpOrderLookupOrCreate({
        rsvpInvitationToken, rsvpGoing: true,
      });
    } else {
      createNewOrder(regFormUuid);
    }

    if (wechatOpenId) {
      alert(`Welcome WeChat user with ID ${wechatOpenId}.`);
    }
  }, []);

  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    if (eventInfo && ogInformation && ua.indexOf('micromessenger') > 0) {
      updateWeChatShare();
    }
  }, [eventInfo, ogInformation, updateWeChatShare, locale]);

  const [slideLeft, setSlideLeft] = useState(false);
  const prevFormStage = usePrevious(formStage);
  useEffect(() => {
    if (prevFormStage < formStage) {
      setSlideLeft(true);
    } else {
      setSlideLeft(false);
    }
  }, [formStage]);

  // reference: https://gomakethings.com/dynamically-changing-the-text-color-based-on-background-color-contrast-with-vanilla-js/
  const fontColor = useMemo(() => {
    let tuneColor = themeColor.slice(1);
    const r = parseInt(tuneColor.substr(0, 2), 16);
    const g = parseInt(tuneColor.substr(2, 2), 16);
    const b = parseInt(tuneColor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    tuneColor = (yiq >= 135) ? 'black' : 'white';
    return tuneColor;
  }, [themeColor]);
  const lastFormStage = useMemo(
    () => (4 + (isBuiltInPaymentEnabled ? 1 : 0)),
    [isBuiltInPaymentEnabled],
  );
  const formPageFormStage = useMemo(
    () => (includeTicketing ? 2 : 1),
    [includeTicketing],
  );
  const paymentFormStage = useMemo(
    () => (includeTicketing ? 3 : 2),
    [includeTicketing],
  );
  const thankYouFormStage = useMemo(
    () => (includeTicketing ? 3 : 2) + (isBuiltInPaymentEnabled ? 1 : 0),
    [includeTicketing, isBuiltInPaymentEnabled],
  );

  return (
    <RootContainer backgroundColor={backgroundColor} fontFamily={fontFamily}>
      <OnlineStatusTracker />
      <Transition
        in={formStage > -1 && formStage < lastFormStage}
        timeout={500}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <FormStageContainer state={state}>
            <FormStage attendeeForm setLargestFormStage={setLargestFormStage} locale={locale} />
          </FormStageContainer>
        )}
      </Transition>
      <Container id="EventXRegForm">
        <Head>
          <title>{localizableEventName}</title>
          <meta property="og:title" content={localizableEventName} />
        </Head>
        <TicketWarningModal />
        <Transition in={formStage === -1} timeout={500} mountOnEnter unmountOnExit>
          {() => (
            <XtraLoadingOverlay isLoading color="#DEDEDE" />
          )}
        </Transition>
        {formError.value ? (
          <Transition in={formError.value} timeout={500} mountOnEnter unmountOnExit>
            {(state) => (
              <StageSwitcher state={state}>
                <FormError reason={formError.reason} />
                <Footer colorMode={footerColorMode} />
              </StageSwitcher>
            )}
          </Transition>
        ) : (
          <>
            <Transition in={formStage === 0} timeout={500} mountOnEnter unmountOnExit>
              {(state) => (
                <StageSwitcher state={state} slideLeft={slideLeft}>
                  <LandingPage themeColor={themeColor} fontColor={fontColor} />
                  <Footer colorMode={footerColorMode} />
                </StageSwitcher>
              )}
            </Transition>
            {includeTicketing && (
              <Transition in={formStage === 1} timeout={500} mountOnEnter unmountOnExit>
                {(state) => (
                  <StageSwitcher state={state} slideLeft={slideLeft}>
                    <Ticketing themeColor={themeColor} fontColor={fontColor} />
                    <Footer colorMode={footerColorMode} />
                  </StageSwitcher>
                )}
              </Transition>
            )}
            <Transition
              in={formStage === formPageFormStage}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              {(state) => (
                <StageSwitcher state={state} slideLeft={slideLeft}>
                  <FormPage themeColor={themeColor} fontColor={fontColor} plan={plan} />
                  <Footer colorMode={footerColorMode} />
                </StageSwitcher>
              )}
            </Transition>
            {isBuiltInPaymentEnabled && (
              <Transition
                in={formStage === paymentFormStage}
                timeout={500}
                mountOnEnter
                unmountOnExit
              >
                {(state) => (
                  <StageSwitcher state={state} slideLeft={slideLeft}>
                    <PaymentPage themeColor={themeColor} fontColor={fontColor} />
                    <Footer colorMode={footerColorMode} />
                  </StageSwitcher>
                )}
              </Transition>
            )}
            <Transition
              in={formStage === thankYouFormStage}
              timeout={500}
              mountOnEnter
              unmountOnExit
            >
              {(state) => (
                <StageSwitcher state={state} slideLeft={false}>
                  <ThankYouPage state="entered" />
                  <Footer colorMode={footerColorMode} />
                </StageSwitcher>
              )}
            </Transition>
          </>
        )}
      </Container>
    </RootContainer>
  );
};

App.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  formStage: PropTypes.number.isRequired,
  createNewOrder: PropTypes.func.isRequired,
  regFormUuid: PropTypes.string.isRequired,
  setRegFormUuid: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  footerColorMode: PropTypes.string.isRequired,
  includeTicketing: PropTypes.bool.isRequired,
  setLargestFormStage: PropTypes.func.isRequired,
  formError: PropTypes.shape().isRequired,
  fontFamily: PropTypes.string,
  setUserLocation: PropTypes.func.isRequired,
  rsvpInvitationToken: PropTypes.string,
  formKind: PropTypes.string.isRequired,
  orderAccessKey: PropTypes.string.isRequired,
  setFormKind: PropTypes.func.isRequired,
  rsvpOrderLookupOrCreate: PropTypes.func.isRequired,
  eventInfo: PropTypes.shape(),
  wechatOpenId: PropTypes.string,
  ogInformation: PropTypes.shape(),
  updateWeChatShare: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

App.defaultProps = {
  rsvpInvitationToken: '',
  fontFamily: 'Lato',
  eventInfo: null,
  ogInformation: null,
  wechatOpenId: null,
};

const mapState = (state) => ({
  form: state.form,
  backgroundColor: state.backgroundColor,
  formStage: state.formStage,
  isLoading: state.isLoading,
  eventInfo: state.eventInfo,
  themeColor: state.themeColor,
  wechatOpenId: state.wechatOpenId,
  footerColorMode: state.footerColorMode,
  orderAccessKey: state.orderAccessKey,
  includeTicketing: state.includeTicketing,
  formError: state.formError,
  fontFamily: state.fontFamily,
  ogInformation: state.ogInformation,
  locale: state.locale,
});

const mapDispatch = ({
  currentInQueue: {
    createNewOrder, orderCancel, rsvpOrderLookupOrCreate,
  },
  regFormElementId: { setRegFormElementId },
  eventId: { setEventId },
  regFormUuid: { setRegFormUuid },
  largestFormStage: { setLargestFormStage },
  userLocation: { setUserLocation },
  formKind: { setFormKind },
  ogInformation: { updateWeChatShare },
}) => ({
  orderCancel,
  createNewOrder,
  setRegFormElementId,
  setEventId,
  setRegFormUuid,
  setLargestFormStage,
  setUserLocation,
  setFormKind,
  rsvpOrderLookupOrCreate,
  updateWeChatShare,
});

export default connect(
  mapState,
  mapDispatch,
)(App);
