import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import XtraFlexbox from 'components/XtraFlexbox';
import XtraH3 from 'components/XtraH3';
import { useTranslation } from 'react-i18next';
import { XtraLoadingOverlay } from 'components';
import OrderSummaryTicketEntry from './OrderSummaryTicketEntry';

const FormTicketSummaryContainer = styled.div`
  position: ${({ forceInline }) => (forceInline ? 'relative' : 'absolute')};
  right: ${({ forceInline }) => (forceInline ? '0' : '-260px')};
  height: 100%;
  width: ${({ forceInline }) => (forceInline ? '100%' : 'initial')};
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 1350px) {
    position: relative;
    width: 100%;
    right: 0;
  }
`;

const FormTicketSummaryStickyContainer = styled.div`
  position: sticky;
  top: 8px;
  width: ${({ forceInline }) => (forceInline ? '100%' : '256px')};
  background-color: #FFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 1350px) {
    position: relative;
    top: 0;
    width: 100%;
    margin: 8px 0;
  }

  @media screen and (max-width: 832px) {
    margin: 0;
    border-radius: 0;
  }
`;

const LoadingIconContainer = styled.div`
  position: relative;
  width: 24px;
  display: inline-block;
  height: auto;
  transform: translateY(${({ translateY }) => (translateY || '-12px')});
  margin-left: ${({ marginLeft }) => (marginLeft || '0')};
`;

const CustomFlexBox = styled(XtraFlexbox)`
  margin-top: 16px;
`;

const FormTicketSummaryTableItem = styled.div`
  flex: 0 0 ${({ size }) => size};
  padding: 8px 0 0;
  color: ${({ fontColor }) => (fontColor || '#484848')};
  text-align: ${({ textAlign }) => (textAlign || 'left')};
  font-weight: ${({ fontWeight }) => (fontWeight || 'normal')};
`;

const FormTicketSummaryHr = styled.hr`
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledXtraH3 = styled(XtraH3)`
  font-family: unset;
`;

const priceReducer = (accumulator, { selectedQuatity, ticket: { price: { amount } } }) => (
  accumulator + selectedQuatity * parseFloat(amount)
);

const quatityReducer = (accumulator, { selectedQuatity }) => (
  accumulator + selectedQuatity
);

const renderTotalPrice = (
  discountCodeEnabled,
  intlActualPrice,
  intlTotalPrice,
) => {
  if (discountCodeEnabled) {
    return intlActualPrice;
  }
  return intlTotalPrice;
};

const FormTicketSummary = ({
  actualPrice,
  discountedPrice,
  discountCodeEnabled,
  ticket,
  isQuotatingPrice,
  locale,
  forceInline,
  themeColor,
}) => {
  const { t } = useTranslation();
  const totalPrice = ticket.map((eachTickek) => ({
    id: eachTickek.id,
    selectedQuatity: eachTickek.selectedQuatity,
    ticket: eachTickek[locale.code].ticket,
  })).reduce(priceReducer, 0);
  const numberFormatter = useMemo(() => {
    let language = 'en';
    if (ticket[0][locale.code].ticket.price.currencyCode === 'EUR') {
      language = 'de';
    }
    return new Intl.NumberFormat(language,
      {
        style: 'currency',
        currency: ticket[0][locale.code].ticket.price.currencyCode,
      });
  }, [locale, ticket]);
  const intlTotalPrice = useMemo(() => (
    numberFormatter.format(totalPrice.toFixed(2))
  ), [totalPrice, numberFormatter]);
  const intlDiscountedPrice = useMemo(() => (
    numberFormatter.format(discountedPrice)
  ), [discountedPrice, numberFormatter]);
  const intlActualPrice = useMemo(() => (
    numberFormatter.format(actualPrice)
  ), [actualPrice, numberFormatter]);

  return (
    <FormTicketSummaryContainer forceInline={forceInline}>
      <FormTicketSummaryStickyContainer forceInline={forceInline}>
        <StyledXtraH3>
          {t('regform_v3.order_summary_box.order_summary', { locale: locale.emsMapping })}
        </StyledXtraH3>
        <CustomFlexBox justifyContent="space-between" wrap="wrap">
          <FormTicketSummaryTableItem size="100%" style={{ paddingBottom: 20 }}>
            {ticket.map((eachTickekPurchase) => (
              <OrderSummaryTicketEntry
                ticketPurchase={eachTickekPurchase}
                locale={locale}
                numberFormatter={numberFormatter}
              />
            ))}
          </FormTicketSummaryTableItem>
          <FormTicketSummaryTableItem size="50%">
            {t('regform_v3.order_summary_box.subtotal', { locale: locale.emsMapping })}
          </FormTicketSummaryTableItem>
          <FormTicketSummaryTableItem size="50%" textAlign="right">
            {intlTotalPrice}
          </FormTicketSummaryTableItem>
          {parseFloat(discountedPrice) > 0 && (
            <>
              <FormTicketSummaryTableItem size="50%" fontColor="#06AF97">
                {t('regform_v3.order_summary_box.discount', { locale: locale.emsMapping })}
              </FormTicketSummaryTableItem>
              <FormTicketSummaryTableItem size="50%" fontColor="#06AF97" textAlign="right">
                {`-${intlDiscountedPrice}`}
              </FormTicketSummaryTableItem>
            </>
          )}
          <FormTicketSummaryTableItem size="100%">
            <FormTicketSummaryHr />
          </FormTicketSummaryTableItem>
          <FormTicketSummaryTableItem size="50%" fontWeight="bold">
            {t('payment.total', { locale: locale.emsMapping })}
          </FormTicketSummaryTableItem>
          <FormTicketSummaryTableItem size="50%" fontColor={themeColor} textAlign="right">
            {isQuotatingPrice ? (
              <LoadingIconContainer marginLeft="4px" translateY="-14px">
                <XtraLoadingOverlay isLoading size={20} thickness={3} color={themeColor} />
              </LoadingIconContainer>
            ) : renderTotalPrice(
              discountCodeEnabled,
              intlActualPrice,
              intlTotalPrice,
            )}
          </FormTicketSummaryTableItem>
        </CustomFlexBox>
      </FormTicketSummaryStickyContainer>
    </FormTicketSummaryContainer>
  );
};

const mapState = (state) => ({
  actualPrice: state.actualPrice,
  discountedPrice: state.discountedPrice,
  discountCodeEnabled: state.discountCodeEnabled,
  ticket: state.ticket,
  locale: state.locale,
  isQuotatingPrice: state.isQuotatingPrice,
  themeColor: state.themeColor,
});

FormTicketSummary.propTypes = {
  actualPrice: PropTypes.string.isRequired,
  discountedPrice: PropTypes.string.isRequired,
  discountCodeEnabled: PropTypes.bool.isRequired,
  ticket: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  themeColor: PropTypes.string.isRequired,
  isQuotatingPrice: PropTypes.bool.isRequired,
  forceInline: PropTypes.bool,
};

FormTicketSummary.defaultProps = {
  forceInline: false,
};

export default connect(mapState, null)(FormTicketSummary);
