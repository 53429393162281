import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import XtraFlexbox from 'components/XtraFlexbox';
import XtraText from 'components/XtraText';
import { useTranslation } from 'react-i18next';
import RedirectText from 'components/RedirectText';

const FormErrorContainer = styled(XtraFlexbox)`
  width: 100%;
  height: 80vh;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 832px) {
    height: calc(100vh - 50px);
  }
`;
const StyledXtraText = styled(XtraText)`
  padding: 4px 0;
`;

const FormErrorTitle = styled(StyledXtraText)`
  font-weight: bold;
  font-size: 2rem;
`;

const FormError = ({
  reason,
  orderCancel,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <FormErrorContainer direction="column" justifyContent="center">
      <FormErrorTitle>{t(`regform_v3.form_error.${reason}.title`)}</FormErrorTitle>
      <StyledXtraText>{t(`regform_v3.form_error.${reason}.content`)}</StyledXtraText>
      <RedirectText
        onClick={onClick || (() => {
          orderCancel();
          window.location.reload();
        })}
      >
        {t(`regform_v3.form_error.${reason}.redirect_text`)}
      </RedirectText>
    </FormErrorContainer>
  );
};

FormError.propTypes = {
  reason: PropTypes.string.isRequired,
  orderCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

FormError.defaultProps = {
  onClick: undefined,
};

const mapDispatch = ({
  currentInQueue: { orderCancel },
}) => ({
  orderCancel,
});

export default connect(null, mapDispatch)(FormError);
