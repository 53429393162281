import React from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const matomoSiteId = Number.parseInt(process.env.NEXT_PUBLIC_MATOMO_SITE_ID, 10);

const matomoInstance = createInstance({
  urlBase: 'https://eventxtra.matomo.cloud/',
  siteId: matomoSiteId,
  linkTracking: false,
  configurations: {
    enableCrossDomainLinking: true,
  },
});

const MatomoContextProvider = ({ children }): JSX.Element => (
  <MatomoProvider value={matomoInstance}>
    {children}
  </MatomoProvider>
);

export default MatomoContextProvider;
